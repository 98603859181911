import { useEffect, useRef, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

// Constants representing the different states of a WebSocket connection
const WebSocketStates = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
} as const;

// Type definition for WebSocket states
type WebSocketStateType = typeof WebSocketStates[keyof typeof WebSocketStates];

// Interface for hook options
interface UseWebSocketOptions {
    onMessage?: (event: MessageEvent) => void;
}

export const useWebSocket = (options: UseWebSocketOptions) => {
    // Ref to store WebSocket instance that persists across renders
    const wsRef = useRef<WebSocket | null>(null);
    // State to track current WebSocket connection status
    const [wsState, setWsState] = useState<WebSocketStateType>(WebSocketStates.CLOSED);
    // Refs for managing reconnection logic
    const reconnectTimeoutRef = useRef<NodeJS.Timeout>();
    const reconnectAttemptsRef = useRef(0);
    // Configuration constants
    const MAX_RECONNECT_ATTEMPTS = 5;
    const RECONNECT_DELAY = 3000; // Base delay in milliseconds

    const setupWebSocket = async () => {
        try {
            // Prevent creating new connection if one is already open
            if (wsRef.current?.readyState === WebSocketStates.OPEN) {
                return;
            }

            setWsState(WebSocketStates.CONNECTING);
            // Get authentication token for secure connection
            const session = await fetchAuthSession();
            wsRef.current = new WebSocket(process.env.REACT_APP_WS + session.tokens.accessToken.toString());

            // Handle successful connection
            wsRef.current.onopen = () => {
                console.log('WebSocket connected');
                setWsState(WebSocketStates.OPEN);
                reconnectAttemptsRef.current = 0; // Reset reconnection attempts on successful connection
            };

            // Handle connection closure with exponential backoff retry
            wsRef.current.onclose = (event) => {
                console.log('WebSocket connection closed:', event.code, event.reason);
                setWsState(WebSocketStates.CLOSED);

                // Implement exponential backoff for reconnection attempts
                if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
                    reconnectAttemptsRef.current += 1;
                    // Calculate delay with exponential backoff
                    const delay = RECONNECT_DELAY * Math.pow(2, reconnectAttemptsRef.current - 1);
                    reconnectTimeoutRef.current = setTimeout(setupWebSocket, delay);
                }
            };

            // Handle connection errors
            wsRef.current.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            // Set message handler from options or use empty function as fallback
            wsRef.current.onmessage = options.onMessage || (() => { return; });

        } catch (error) {
            console.error('Error setting up WebSocket:', error);
            setWsState(WebSocketStates.CLOSED);
            
            // Attempt to reconnect on setup failure
            if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
                reconnectTimeoutRef.current = setTimeout(setupWebSocket, RECONNECT_DELAY);
            }
        }
    };

    // Utility function to send messages through the WebSocket
    const sendMessage = (message: any) => {
        if (wsRef.current?.readyState === WebSocketStates.OPEN) {
            try {
                wsRef.current.send(JSON.stringify(message));
            } catch (error) {
                console.error('Error sending WebSocket message:', error);
            }
        } else {
            console.warn('WebSocket is not open, message not sent');
        }
    };

    // Setup WebSocket connection on component mount and cleanup on unmount
    useEffect(() => {
        setupWebSocket();

        // Cleanup function to handle component unmounting
        return () => {
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (wsRef.current) {
                wsRef.current.close(1000, 'Component unmounting');
                wsRef.current = null;
            }
            setWsState(WebSocketStates.CLOSED);
        };
    }, []);

    // Return hook interface
    return {
        sendMessage,
        wsState,
        WebSocketStates
    };
}; 