import React, { CSSProperties, FC, PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';

type ContextType = {
    overlay: HTMLDivElement;
};

const overlayContext = React.createContext<ContextType | null>(null);
export const useOverlayContext = () => {
    const value = React.useContext(overlayContext);
    if (!value) {
        throw new Error('useOverlay must be used within an OverlayProvider');
    }
    return value;
};


const OverlayProvider: FC<PropsWithChildren> = ({ children }) => {
    const overlayRef = React.useRef<HTMLDivElement>(null);

    const [rendered, setRendered] = React.useState(false);
    useEffect(() => {
        setRendered(true);
        return () => setRendered(false);
    }, []);

    return (
        <>
            <div style={{ display: 'contents', isolation: 'isolate', zIndex: 0 }}>
                {rendered ? (
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    <overlayContext.Provider value={{ overlay: overlayRef.current! }}>
                        {children}
                    </overlayContext.Provider>
                ) : null}
            </div>
            <div
                className="overlay"
                // style={{
                //     position: 'fixed',
                //     inset: 0,
                //     overflow: 'hidden',
                //     isolation: 'isolate',
                //     zIndex: 1,
                //     pointerEvents: 'none'
                // }} // TODO
                ref={overlayRef}
            />
        </>
    );
};

type AnchoredProps = {
    to: React.RefObject<HTMLElement>;
    children: React.ReactNode;
    menuTopAndOptions?: number;
    options?: number
};

/** wraps children in a parent element matching position and size of the provided element for absolute positioning */
export const Anchored: FC<AnchoredProps> = ({ to, children, menuTopAndOptions, options }) => {
    const { overlay } = useOverlayContext();
    if (!to.current) return null;
    const anchorBounds = to.current.getBoundingClientRect();
    const overlayBounds = overlay.getBoundingClientRect();

    const style: CSSProperties = {
        position: 'absolute',
        top: anchorBounds.y - overlayBounds.y - (menuTopAndOptions ? menuTopAndOptions <= 5 ? menuTopAndOptions * 40 + 5 : 5 * 40 + 5 : -45),
        left: anchorBounds.x - overlayBounds.x,
        width: anchorBounds.width,
        height: anchorBounds.height,
    };

    const screenHeight = window.innerHeight;
    if ((Number(style.top) + options * 40 + 5) > screenHeight) {
        style.top = anchorBounds.y - overlayBounds.y - (options <= 5 ? options * 40 : 205)
    }

    return ReactDOM.createPortal(
        <div className="overlay-anchor" style={style}>
            {children}
        </div>,
        overlay
    );
};

export default OverlayProvider;
