import React from 'react'

const Chevron = ({ gray, sidebar, open }: { gray?: boolean, sidebar?: boolean, open?: boolean }) => {
  if (sidebar) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
      >
        <path
          d='M5.24536 7.51025L10.2463 12.5111L15.2472 7.51025'
          strokeWidth='1.66697'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
  return !gray ? (
    <svg style={{ transform: !open ? 'rotate(0deg)' : 'rotate(180deg)' }} xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'>
      <path
        d='M5.24536 7.51025L10.2463 12.5111L15.2472 7.51025'
        stroke='#FF9900'
        strokeWidth='1.66697'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ) : (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M4.99658 7.49475L9.99311 12.4913L14.9896 7.49475'
        stroke='white'
        strokeWidth='1.66551'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Chevron
