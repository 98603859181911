import styled from 'styled-components'
import { EDCDetails, EDCDetailsIcon } from '../Dashboard.styled'

export const DBTaskCard = styled.div`
  border-radius: 12.5px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  height: 130px;
  width: 100%;
  display: flex;
  padding: 1rem;


  @media (max-width: 500px) {
    height: max-content;
    align-items: center;
  }


`



export const DBSingleTaskContainer = styled.div`

  display: flex;
  gap: 1rem;
  margin-top: 30px;

  @media (max-width: 500px) {
    flex-direction: column;
  }

`

export const DBSingleTaskSection = styled.div`

display: flex;
flex-direction: column;

@media (max-width: 500px) {
   width: 100% !important ;
  }

`


export const DBTaskLongCard = styled.div`
  border-radius: 12.5px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  height: 180px;
  width: 100%;
  display: flex;
  padding: 1.5rem;
  margin: 20px 0;
  overflow: auto;
  h1 {
    text-transform: uppercase;
    color: ${(props) => props.theme.colorGray};
    font-size: 12px;
  }



  .wrapper{
    height: 100%;
    width:100%;
    display: flex;
    flex-direction:column;
    justify-content:flex-between;
    gap: 1rem;

  }



  @media (max-width: 500px) {
   width: 100%;
   .wrapper{
     width: max-content;
   }
  }

`

export const DBTaskDetailsCard = styled.div`
  width: 100%;
  height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.5px solid ${(props) => props.theme.colorLightGray};
  border-radius: 8px;
  padding: 1rem;

  svg {
    fill: ${(props) => props.theme.colorOrange};
  }

  h1,
  p {
    color: ${(props) => props.theme.colorGray};
    font-size: 13px;
    text-transform: none;
  }

  @media (max-width: 500px) {
   width: max-content;
   
  }

`

export const DBTCIcon = styled(EDCDetailsIcon)`
  width: 30%;
  margin-right: 0.5rem;
`
interface IDBTCDetails {
  next?: boolean
}

export const DBTCDetails = styled(EDCDetails)<IDBTCDetails>`
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1rem;
  width: 70%;
  ${(props) => props.next && 'justify-content: flex-start; gap:5px;'}

  h3 {
    font-size: 16px;
    color: ${(props) => props.theme.colorGray};
  }

  h1 {
    color: ${(props) => props.theme.colorOrange};
    font-size: 32px;
  }

  p {
    font-size: 11px;
    color: ${(props) => props.theme.colorGray};
  }
`

export const DBTaskGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media (max-width: 500px) {

    display: flex;
    flex-direction: column;

  }
  
`

export const DBTaskGridCard = styled.div`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  min-height: 180px;
  height: 180px;
  padding: 1.5em;

  h1,
  p {
    color: ${(props) => props.theme.colorGray};
    font-size: 12px;
  }
  h1 {
    text-transform: uppercase;
  }
`

interface IDBTaskTimelineItem {
  open?: boolean
  margin?: boolean
}

export const DBTaskTimelineItem = styled.div<IDBTaskTimelineItem>`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  height: max-content;
  cursor: pointer;
  animation: 0.2s ease-in;
  margin: ${(props) => (props.margin ? '1rem 0' : '0')};
`

interface IDBTimelineTitle {
  open?: boolean
  status: number
}
export const DBTimelineTitle = styled.div<IDBTimelineTitle>`
  width: 100%;
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: 0.2s ease-in;

  h3,
  p {
    color: ${(props) => props.theme.colorGray};
  }

  h3 {
    font-weight: bold;
    font-size: 13px;
  }

  p {
    font-size: 11px;
  }

  svg {
    fill: ${(props) =>
      props.status == 3
        ? props.theme.colorGreen
        : props.status == 4 || props.status == 7
        ? props.theme.colorRed
        : props.theme.colorOrange};
  }
`

interface IDBTimelineLogContainer {
  open?: boolean
}

export const DBTimelineLogContainer = styled.div<IDBTimelineLogContainer>`
  width: 100%;
  height: 0px;
  ${(props) => props.open && 'height:max-content'};
  animation: 1s ease-in;
  overflow: hidden;
  padding: 0px 25px;
  cursor: auto;
`
export const TimelineLineContainer = styled.div`
  width: 100%;
  height: max-content;
  padding-bottom: 2rem;
  border-top: 0.3px solid ${(props) => props.theme.colorGray};
  padding-top: 25px;
`

export const TimelineLog = styled.div`
  width: 100%;
  height: 50px;
  color: ${(props) => props.theme.colorGray};
  display: flex;

  .time {
    max-width: 80px;
    height: 100%;
    font-size: 11px;
    text-align: end;
  }

  .task-status {
    color: ${(props) => props.theme.colorGray};
    font-size: 13px;
  }

  .svg {
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .view-image {
    color: ${(props) => props.theme.colorOrange};
    font-size: 11px;
    cursor: pointer;
  }
`
interface IDBTaskStatus {
  status?: number
}

export const DBTaskStatus = styled.div<IDBTaskStatus>`
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    font-size: 13px;
    color: ${(props) => props.theme.colorGray};
  }

  .svg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${(props) =>
      props.status == 3
        ? props.theme.colorGreen
        : props.status == 4 || props.status == 7
        ? props.theme.colorRed
        : '#fff'};
    filter: drop-shadow(0px 0px 25.00447654724121px rgba(0, 0, 0, 0.1));
    ${(props) =>
      props.status !== 3 &&
      props.status !== 4 &&
      'filter: drop-shadow(0px 0px 25.00447654724121px rgba(0, 0, 0, 0.10));'}

    svg {
      fill: ${(props) =>
        props.status !== 3 && props.status !== 4  && props.status !== 7 ? props.theme.colorOrange : '#fff'};
    }
  }
`
