import React, { useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Flex } from 'styling/GlobalStyles';
import { TasksModalContainer } from './DBMachines.styled';
import { CloseIcon } from 'assets/icons/CloseIcon';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { TimelineItem } from '../DashboardTasks/DBSingleTask';
import { IMachineTask } from 'features/dashboard/types';
import { ImagesSlider } from 'components/ImagesSlider/ImagesSlider';

export const TasksModal = ({ setTasksModal, id }: { setTasksModal: any, id: string }) => {
    const [tasks, setTasks] = useState<IMachineTask[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [imagesModal, setImagesModal] = useState({ open: false, images: [], details: '' })


    const handleImages = (images: string[], details: string) => {
        setImagesModal(prev => {
            const copy = structuredClone(prev)
            copy.images = images
            copy.details = details
            copy.open = true
            return copy
        })
    }

    const fetchTasks = useCallback(async () => {
        if (loading) return;
        setLoading(true);
        try {
            const session = await fetchAuthSession();
            const response = await axios.get(process.env.REACT_APP_API + '/machines/' + id + '/info/tasks', {
                params: { id, page, limit: 15 },
                headers: { Authorization: `Bearer ${session.tokens.accessToken.toString()}` }
            });

            if (response.data.length > 0) {
                setTasks(prevTasks => [...prevTasks, ...response.data]);
                setPage(prevPage => prevPage + 1);
                setHasMore(response.data.length === 15); // Assuming full page means more data exists
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching tasks:', error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    }, [id, page]);

    useEffect(() => {
        fetchTasks();
    }, []);

    return (
        <TasksModalContainer>
            <Flex width='100%' between center>
                <h4>Tasks Timeline</h4>
                <CloseIcon onClick={() => setTasksModal(false)} />
            </Flex>
            <div
                className='list'
                id='scrollableDiv'
            >
                <InfiniteScroll
                    dataLength={tasks.length}
                    next={fetchTasks}
                    hasMore={hasMore}
                    style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem' }}
                    loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                >
                    {tasks.map((task) => (
                        <TimelineItem
                            handleImages={handleImages}
                            key={task.id}
                            data={task}
                        />
                    ))}
                </InfiniteScroll>
            </div>
            {imagesModal.open && (
                <ImagesSlider images={imagesModal.images} setImagesModal={setImagesModal} />
            )}
        </TasksModalContainer >
    );
};