import React, { useState } from 'react'
import {
  ProfilePicture,
  Button,
  Separator,
  Flex,
  MainHeadline,
  Table,
  Tr,
  Th,
  PageWrapper,
  PageFooter,
} from '../../../styling/GlobalStyles'
import { Td } from 'components/Td/Td'
import { useNavigate } from 'react-router-dom'
import Dozer from '../../../assets/images/Dozer.png'
import { Pagination } from '../../../components/Pagination/Pagination'
import { NavBar } from '../../../components/NavBar/NavBar'
import {
  clearModelsList,
  deleteMachineModel,
  getMachineModels,
} from '../../../features/machineModels/machineModelsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getParams, handlePagination } from 'utils/helpers'
import { AppDispatch, RootState } from 'store'
import { Options } from 'components/Options/Options'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { setConfirmModal } from 'features/global/globalSlice'
import { IMachineModelTable } from 'types/machineModels.interface'
import { ModalTypes } from 'types/global.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const ResourcesModels = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { machineModels, noMachineModels, totalPages } = useSelector((store: RootState) => store.machineModels)
  const [params, setParams] = useState<any>(getParams({ location, limit: 5 }))

  useQueryPagination({ fetchFunctions: [getMachineModels], cleanUpFunctions: [clearModelsList], params })

  const handleDeleteModel = async (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteMachineModel(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: machineModels.length < 2,
        },
        word: 'delete model',
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Models...' setParams={setParams} limit={5} />
      <PageWrapper>
        <Flex between center>
          <MainHeadline>Machinery Models</MainHeadline>
          <Button orange onClick={() => navigate('add')}>
            Add Machinery Model
          </Button>
        </Flex>
        <Separator />
        {noMachineModels ? (
          <NoItems title='Machine Models' />
        ) : (
          <Table>
            <Th mb='0.5rem'>
              <Td w='20%' noTooltip text='Model' />
              <Td w='20%' noTooltip text='Category' />
              <Td w='20%' noTooltip text='Make' />
              <Td w='15%' noTooltip text='Fuel Type' />
              <Td w='25%' noTooltip text='Operations' />
            </Th>
            {
              machineModels &&
              machineModels?.map((item: IMachineModelTable, i: number) => {
                return (
                  <Tr key={i}>
                    <Td w='25%' text={item?.model}>
                      <Flex center gap='1'>
                        <ProfilePicture mr='10'>
                          <img
                            src={item?.image}
                            alt=''
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null
                              currentTarget.src = Dozer
                            }}
                          />
                        </ProfilePicture>
                        {item?.model}
                      </Flex>
                    </Td>

                    <Td w='25%' text={item?.category} />
                    <Td w='25%' text={item?.make} />
                    <Td w='20%' text={item?.fuelType} />
                    <Td w='25%' text={item?.operationsCount} />
                    <Td w='5%'>
                      <Options
                        handleRemove={() => handleDeleteModel(item?.id)}
                        item={item}
                        title='Machine Model'
                      />
                    </Td>
                  </Tr>
                )
              })}
          </Table>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 5)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
