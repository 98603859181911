import React, { useState } from 'react'
/*eslint-disable*/
import {
  LoginWrapper,
  LoginContainer,
  LoginDivider,
  PhoneNumberError,
} from './Login.styled'
import { Button, Flex, H2, Logo, MainHeadline, Separator } from '../../styling/GlobalStyles'
import { InputWrapper } from 'components/Input/Input.styled'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { Spinner } from 'components/Loading/Loading'
import { Input } from 'components/Input/Input'
import { Amplify } from 'aws-amplify'
import { amplifyConfig } from 'config'
import { LoginPassword } from 'components/Input/LoginPassword'
import { setLoadingAuth } from 'features/global/globalSlice'
import { toast } from 'react-toastify'
import { PasswordResetModal } from './PasswordResetModal'
import { confirmSignIn, signIn, SignInOutput } from 'aws-amplify/auth'
Amplify.configure(amplifyConfig)

const Inputs = ({ newPass, setNewPass }) => {
  const [error, setError] = useState('')
  const [user, setUser] = useState<any>({})
  const dispatch = useDispatch<AppDispatch>()
  const [passwordModal, setPasswordModal] = useState({ open: false })
  const [state, setState] = useState({
    username: '',
    password: [],
    newPassword: [],
    newPasswordVerify: []
  })

  const closeModal = () => setPasswordModal({ open: false })

  const forgotPassword = async () => {
    setPasswordModal({ open: true })
  }

  const verify = async () => {
    try {
      if (!state?.username || state.password.join('').length < 6) {
        toast.warn('Both username and password must be filled')
        return
      }
      dispatch(setLoadingAuth(true))

      if (newPass) {
        if (state.newPassword.join('') == state.newPasswordVerify.join('')) {
          await confirmSignIn({
            challengeResponse: state.newPassword.join(''),
          });

          dispatch(setLoadingAuth(false))
          window.location.reload()
        } else {
          setError('Passwords are not valid or not matching')
          dispatch(setLoadingAuth(false))
        }
      } else {
        // const user = await signIn(state.username, state.password.join(''));
        const user: SignInOutput = await signIn({
          username: state.username,
          password: state.password.join('')
        });
        setUser(user)
        dispatch(setLoadingAuth(false))
        if (user.nextStep.signInStep == 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          setNewPass(true)
        } else {
          window.location.reload()
        }
      }
    } catch (error) {
      setError(error.message);
      toast.error(error.message)
      dispatch(setLoadingAuth(false))
    }
  }


  const handleChange = (e) => {
    const { value, name } = e.target
    setState(prev => {
      const copy = structuredClone(prev)
      copy[name] = value
      return copy
    })
    return null
  }

  return (
    <>
      <div tabIndex={1} onKeyDown={(e) => e.key === 'Enter' && verify()}>
        {newPass ? <Flex column gap='1rem' >
          <LoginPassword password={state.newPassword} setState={setState} field='newPassword' labelText="Enter new password" />
          <LoginPassword password={state.newPasswordVerify} setState={setState} field='newPasswordVerify' labelText="Confirm new password" />
        </Flex> :
          <>
            <Input type='text' name='username' onChange={handleChange} value={state.username} labelText="Username" />
            <LoginPassword password={state.password} setState={setState} field='password' labelText='Password' />
          </>}
        {error && (
          <PhoneNumberError className='absolute'>{error}</PhoneNumberError>
        )}
      </div>

      <Button orange mt='50' onClick={verify} width='346' height='48'>
        Sign In
      </Button>
      {!newPass && <p onClick={forgotPassword} style={{ marginTop: '5px', textDecoration: 'underline', textAlign: 'center', cursor: 'pointer' }} >Forgot Password?</p>}
      {passwordModal.open && <PasswordResetModal close={closeModal} />}
    </>
  )
}


export const Login = () => {
  const [newPass, setNewPass] = useState(false)
  const authLoading = useSelector((state: RootState) => state.globals.isLoadingAuth)

  return (
    <LoginWrapper >
      <LoginDivider>
        <LoginContainer>
          <Logo />
          <Spinner loading={authLoading} />
          <Separator />
          {newPass ? <H2 noUpperCase mb='1' style={{ color: '#ff9900', textAlign: 'center' }} >Sign in successful, please set a new password</H2> : <MainHeadline w='100%' mb='2'>
            Welcome!
          </MainHeadline>}
          <InputWrapper>
            <Inputs newPass={newPass} setNewPass={setNewPass} />
          </InputWrapper>
        </LoginContainer>
      </LoginDivider>
    </LoginWrapper>
  )
}

