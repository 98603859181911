import { Title } from 'components/Dashboard/Title'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'store'
import { DashboardCard, DCContent, DCTaskCount, Flex, P } from 'styling/GlobalStyles'
import { groputDashboardItems } from 'utils/helpers'
import { WidgetEditOverlay } from '../Dashboard.styled'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { IWidget } from 'types/dashboard.interface'
import { WorkflowIcon } from 'assets/icons/WorkflowIcon'

export const WorkflowsWidget = ({
    modalOpen,
    removeWidget,
    onDragOver,
    onDragStart,
    onDragDrop
}: IWidget) => {
    const navigate = useNavigate()
    const { workflowStatuses } = useSelector((state: RootState) => state.dashboard)
    const getTaskStatus = (name: string): number => {
        return Number(tasksData?.find((td) => td.name == name)?.value) || 0
    }
    const tasksData = groputDashboardItems(workflowStatuses)

    return (
        <DashboardCard
            draggable={modalOpen}
            onDragStartCapture={() => {
                onDragStart('workflows', 1)
            }}
            onDrop={onDragDrop}
            onDragEnd={(e) => {
                e.preventDefault()
            }}
            onDragOver={(e) => {
                e.preventDefault()
                onDragOver('workflows')
            }}
            width='1fr'
            style={{ gridArea: 'workflows', cursor: modalOpen ? 'grab' : 'normal' }}
        >
            {modalOpen && (
                <WidgetEditOverlay
                    onClick={(e) => {
                        removeWidget('workflows')
                        e.stopPropagation()
                    }}
                >
                    <P>Remove Widget</P>
                    <CloseIcon white />
                </WidgetEditOverlay>
            )}
            <Title
                mainIcon={WorkflowIcon}
                title='Workflows'
                subtitle='All statuses'
                arrow
                onClick={() => !modalOpen && navigate('/dashboard/workflows')}
            />
            <DCContent height='100%'>
                <Flex gap='1rem' width='100%'>
                    <DCTaskCount flex>
                        <p>Not Started:</p>
                        <p className='bold'>{getTaskStatus('Not Started')}</p>
                    </DCTaskCount>
                    <DCTaskCount flex>
                        <p>Completed:</p>
                        <p className='bold'>{getTaskStatus('Completed')}</p>
                    </DCTaskCount>
                </Flex>
                <Flex gap='1rem' width='100%'>
                    <DCTaskCount flex>
                        <p>In Progress:</p>
                        <p className='bold'>{getTaskStatus('In Progress')}</p>
                    </DCTaskCount>
                    <DCTaskCount flex>
                        <p>Idle:</p>
                        <p className='bold'>{getTaskStatus('Idle')}</p>
                    </DCTaskCount>
                </Flex>
                <Flex gap='1rem' width='100%'>
                    <DCTaskCount flex>
                        <p>Paused:</p>
                        <p className='bold'>{getTaskStatus('Paused')}</p>
                    </DCTaskCount>

                    <DCTaskCount width='100%' flex>
                        <p>Stopped:</p>
                        <p className='bold'>{getTaskStatus('Stopped')}</p>
                    </DCTaskCount>
                </Flex>
            </DCContent>
        </DashboardCard>
    )
}
