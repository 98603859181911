import React from 'react'

export const WorkflowIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.25 8.4375C1.25 7.74714 1.80964 7.1875 2.5 7.1875H5.625C6.31536 7.1875 6.875 7.74714 6.875 8.4375V11.5625C6.875 12.2529 6.31536 12.8125 5.625 12.8125H2.5C1.80964 12.8125 1.25 12.2529 1.25 11.5625V8.4375ZM5.625 8.4375H2.5V11.5625H5.625V8.4375Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.875 3.75C11.875 3.05964 12.4346 2.5 13.125 2.5H16.875C17.5654 2.5 18.125 3.05964 18.125 3.75V7.5C18.125 8.19036 17.5654 8.75 16.875 8.75H13.125C12.4346 8.75 11.875 8.19036 11.875 7.5V3.75ZM16.875 3.75H13.125V7.5H16.875V3.75Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.875 12.5C11.875 11.8096 12.4346 11.25 13.125 11.25H16.875C17.5654 11.25 18.125 11.8096 18.125 12.5V16.25C18.125 16.9404 17.5654 17.5 16.875 17.5H13.125C12.4346 17.5 11.875 16.9404 11.875 16.25V12.5ZM16.875 12.5H13.125V16.25H16.875V12.5Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.625 10C5.625 9.65482 5.90482 9.375 6.25 9.375H9.375C9.72018 9.375 10 9.65482 10 10C10 10.3452 9.72018 10.625 9.375 10.625H6.25C5.90482 10.625 5.625 10.3452 5.625 10Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.2474 6.24999C11.0834 6.24931 10.9209 6.2811 10.7692 6.34355C10.6176 6.40599 10.4798 6.49784 10.3638 6.61381C10.2478 6.72978 10.156 6.86756 10.0935 7.01921C10.0311 7.17086 9.99931 7.33338 9.99999 7.49738L10 7.5L10 12.5026C9.99931 12.6666 10.0311 12.8291 10.0935 12.9808C10.156 13.1324 10.2478 13.2702 10.3638 13.3862C10.4798 13.5022 10.6176 13.594 10.7692 13.6565C10.9209 13.7189 11.0834 13.7507 11.2474 13.75L11.25 13.75L12.5 13.75C12.8452 13.75 13.125 14.0298 13.125 14.375C13.125 14.7202 12.8452 15 12.5 15H11.2513M8.75 7.50122V12.4988V7.50122ZM11.2512 5H12.5C12.8452 5 13.125 5.27982 13.125 5.625C13.125 5.97018 12.8452 6.25 12.5 6.25L11.2474 6.24999'
    />
  </svg>
)
