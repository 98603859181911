import { BlankFileIcon } from 'assets/icons/BlankFileIcon'
import { CheckIcon } from 'assets/icons/CheckIcon'
import Chevron from 'assets/icons/Chevron'
import { PendingIcon } from 'assets/icons/PendingIcon'
import { StopIcon } from 'assets/icons/StopIcon'
import { DatePickerInput } from 'components/DatePicker/DatePicker'
import { Filter } from 'components/Filter/Filter'
import { FilterEnum } from 'components/Filter/SingleFilter'
import { NavBar } from 'components/NavBar/NavBar'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Pagination } from 'components/Pagination/Pagination'
import { Td } from 'components/Td/Td'
import { endOfWeek } from 'date-fns/esm'
import { clearDBCycles, getDBCycles, getDBCyclesInfo } from 'features/dashboard/dbTasksSlice'
import { ISingleDBCycle } from 'features/dashboard/types'
import useQueryPagination from 'hooks/useQueryPagination'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'store'
import { EditContainer, Flex, H1WithBack, PageControls, PageFooter, PageWrapper, SubsContainer, Table, Th, Tr, TrChevronContainer, TrContentContainer } from 'styling/GlobalStyles'
import { convertToDate, getParams, getTime } from 'utils/helpers'
import { DBCardsContainer } from '../Dashboard.styled'
import { taskStatusesData } from '../dashboardData'
import { DBTaskCard, DBTCDetails, DBTCIcon } from './DBTasks.styled'
import { ArrowLeftColorless } from 'assets/icons/ArrowLeftColorless'

export const DBTaskCycles = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const [params, setParams] = useState<any>(getParams({
        location, ap: {
            dateFrom: new Date(searchParams.get('dateFrom') ?? new Date()),
            dateTo: endOfWeek(new Date(searchParams.get('dateTo') ?? new Date()), { weekStartsOn: 1 }),
        },
        localeDateString: true
    }))
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const { noCycles, cycles, cyclesInfo } = useSelector((state: RootState) => state.dbTasks)

    useQueryPagination({ fetchFunctions: [getDBCycles, getDBCyclesInfo], cleanUpFunctions: [clearDBCycles], params })

    const getPage = (arr: any[]) => {
        const startIndex = (page - 1) * 5;
        const endIndex = startIndex + 5;

        return arr?.slice(startIndex, endIndex);
    }

    const setDateFrom = (value) => {
        setParams(prev => {
            const copy = structuredClone(prev)
            copy.dateFrom = convertToDate(value)
            return copy
        })
    }

    const setDateTo = (value) => {
        setParams(prev => {
            const copy = structuredClone(prev)
            copy.dateTo = convertToDate(value)
            return copy
        })
    }

    const filters = [
        {
            type: FilterEnum.FIXED_LIST,
            name: 'Difference',
            state: null,
            propName: 's',
            sd: true,
            single: true,
            filterOptions: [
                {
                    name: 'Cost - From Low To High',
                    id: 'costDifference-a'
                },
                {
                    name: 'Cost - From High To Low',
                    id: 'costDifference-d'
                },
                {
                    name: 'Duration - From Low To High',
                    id: 'durationDifference-a'
                },
                {
                    name: 'Duration - From High To Low',
                    id: 'durationDifference-d'
                },
            ]
        },
        {
            type: FilterEnum.SELECT,
            name: 'Status',
            options: taskStatusesData.map(s => ({ label: s.name, value: s.id })),
            propName: 'status'
        },

    ]


    const TaskRow = ({ task, sub, location }: { task: ISingleDBCycle, sub?: boolean, location?: string }) => {
        const [open, setOpen] = useState(false)

        return < Tr hasSubs={true} sub={sub} open={open} overflowHidden={!open} pointer onClick={!sub ? () => setOpen(!open) : () => navigate('/dashboard/tasks/' + task.id)} >
            <TrContentContainer>
                <Td w='15%' text={task?.template.name} />
                <Td w='18%' text={location ?? '-'} />
                <Td w='18%'>
                    <p>
                        {'$ ' + (task?.actualCost ? task.actualCost.toFixed(2) : '/')}
                    </p>
                </Td>
                <Td w='15%'>
                    {task?.totalDuration ? getTime(task?.totalDuration) : '-'}
                </Td>
                <Td w='10%'>
                    <p style={(task?.status == 4 || task?.status == 3 || task?.status == 7) ? { color: task?.status == 3 ? '#00B04C' : '#F00' } : {}} >
                        {taskStatusesData.find(s => s.id == task.status).name}
                    </p>
                </Td>
                <Td w='15%' >
                    <p style={task?.issue ? { color: '#F00' } : {}} >{task?.issue || '-'}</p>

                </Td>
                <Td action w='5%' >
                    {!sub ? <TrChevronContainer open={open}>
                        <Chevron />
                    </TrChevronContainer> : <Td noTooltip>
                        <EditContainer onClick={() => navigate('/dashboard/tasks/' + task.id)} >
                            <Flex style={{ transform: 'rotate(180deg)' }} className='arrow-table' >
                                {ArrowLeftColorless}
                            </Flex>
                        </EditContainer>
                    </Td>}
                </Td>

            </TrContentContainer>
            <SubsContainer>
                {task.tasks.map((t, i) => <TaskRow sub={true} key={i} location={t.location.name} task={
                    {
                        template: { name: t.cycleInfo.templateStep.name, id: null },
                        actualCost: t.actualCost,
                        status: t.status,
                        issue: t.issue,
                        totalDuration: t.totalDuration,
                        tasks: [],
                        id: t.id

                    }} />)}
            </SubsContainer>

        </Tr>

    }
    return <>
        <NavBar />
        <PageWrapper>
            <PageControls>
                <H1WithBack title='Task Cycles' arrow link='/dashboard' />
                <Flex gap='1rem' center>
                    <DatePickerInput
                        maxDate={new Date(params.dateTo)}
                        label='Start date'
                        date={new Date(params.dateFrom || new Date())}
                        setDate={setDateFrom}
                    />
                    <DatePickerInput
                        minDate={new Date(params.dateFrom)}
                        label='End date'
                        date={new Date(params.dateTo || new Date())}
                        setDate={setDateTo}
                    />
                    <Filter options={filters} setPageParams={setParams} />
                </Flex>
            </PageControls>
            <DBCardsContainer>
                <DBTaskCard>
                    <DBTCIcon>
                        {BlankFileIcon}
                    </DBTCIcon>
                    <DBTCDetails>
                        <h3>Total Cycles</h3>
                        <h1>{cyclesInfo?.totalCount || 0}</h1>
                        <Flex column>
                            <p>{'Total Costs: $' + (cyclesInfo?.totalActualCost?.toFixed(2) ?? 0)}</p>
                            <p>{'Total Duration: ' + getTime(cyclesInfo?.totalDuration)}</p>
                        </Flex>
                    </DBTCDetails>
                </DBTaskCard>
                <DBTaskCard>
                    <DBTCIcon>
                        {PendingIcon}
                    </DBTCIcon>
                    <DBTCDetails next >
                        <h3>Total In Progress</h3>
                        <h1>{cyclesInfo?.totalInProgressCount || 0}</h1>
                    </DBTCDetails>
                </DBTaskCard>
                <DBTaskCard>
                    <DBTCIcon>
                        {StopIcon}
                    </DBTCIcon>
                    <DBTCDetails>
                        <h3>Total Stopped</h3>
                        <h1>{cyclesInfo?.totalStoppedCount || 0}</h1>
                        <Flex column>
                            <p>{'Total Costs: $' + (cyclesInfo?.totalStoppedActualCost ?? 0)}</p>
                            <p>{'Total Duration: ' + getTime(cyclesInfo?.totalStoppedDuration)}</p>
                        </Flex>
                    </DBTCDetails>
                </DBTaskCard>
                <DBTaskCard>
                    <DBTCIcon>
                        {CheckIcon}
                    </DBTCIcon>
                    <DBTCDetails>
                        <h3>Total Completed</h3>
                        <h1>{cyclesInfo?.totalCompletedCount || 0}</h1>
                        <Flex column>
                            <p>{'Total Costs: $' + (cyclesInfo?.totalCompletedActualCost ?? 0)}</p>
                            <p>{'Total Duration: ' + getTime(cyclesInfo?.totalCompletedDuration)}</p>
                        </Flex>
                    </DBTCDetails>
                </DBTaskCard>
            </DBCardsContainer>
            {noCycles ? <NoItems marginTop='40px' title='Cycles' /> : <Table style={{ marginTop: '40px' }} >
                <Th next >
                    <Td w='15%' noTooltip text='Task Name' />
                    <Td w='18%' noTooltip text='Location' />
                    <Td w='18%' noTooltip text='Cost' />
                    <Td w='15%' noTooltip text='Duration' />
                    <Td w='10%' noTooltip text='Status' />
                    <Td w='15%' noTooltip text='Alerts' />
                </Th>

                {
                    getPage(cycles)?.map((item: ISingleDBCycle) => {
                        let location = item.tasks[0].location.name
                        if (!item.tasks.every(t => t.location.name == location)) {
                            location = 'Multiple locations'
                        }
                        return <TaskRow task={item} location={location} key={item.id} />
                    })
                }
            </Table>}
        </PageWrapper>
        <PageFooter>
            <Pagination
                setPage={(page) => setPage(page)}
                pageCount={Math.ceil(cycles.length / 5)}
                forcePage={(page ? page : 1) - 1}
            />
        </PageFooter>
    </>
}