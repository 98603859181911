import { useState, useEffect, useRef } from 'react'
import { FilterIcon } from 'assets/icons/FilterIcon'
import { FilterIconContainer, FiltersContainer, FiltersContainerTitle } from './Filter.styled'
import { Flex } from 'styling/GlobalStyles'
import { SingleFilter } from './SingleFilter'
import { removeNullProperties } from 'utils/helpers'

export const Filter = ({ options, setPageParams, pageParams }: { options: any; setPageParams: any, pageParams?: any }) => {
  const [open, setOpen] = useState(false)
  const optionsRef = useRef(null)
  const extractSelectedOptions = () => {
    const arr = {}
    const localParams = Object.fromEntries(new URLSearchParams(location.search))
    for (const key in localParams) {
      if (key.includes('Ids') && !Array.isArray(localParams[key])) {
        arr[key] = (localParams[key].split(','))
      }
    }
    return arr;
  }

  const [selectedOptions, setSelectedOptions] = useState(extractSelectedOptions())


  const hideOnClickOutside = (e) => {
    if (optionsRef.current && !optionsRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 27) {
        event.preventDefault()
        setOpen(false)
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('click', hideOnClickOutside, true)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const handleSave = (values?: any) => {
    setPageParams((prev) => {
      let copy = structuredClone(prev)
      if (typeof values == 'object') {
        copy = { ...copy, ...removeNullProperties(values) }
      } else {
        copy = { ...copy, ...selectedOptions }
      }
      copy.page = 1
      return copy
    })
  }


  return (
    <Flex end='true'>
      <FilterIconContainer onClick={() => setOpen(true)}>
        {FilterIcon}
        {open && (
          <FiltersContainer ref={optionsRef}>
            <FiltersContainerTitle>
              <h3>Filters</h3>
              <p onClick={() => handleSave()}>Save</p>
            </FiltersContainerTitle>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {options.map((o, i) => (
                <SingleFilter
                  filterOptions={o.filterOptions}
                  key={i}
                  filterParams={selectedOptions}
                  setFilterParams={setSelectedOptions}
                  link={o.link}
                  handleSave={handleSave}
                  name={o.name}
                  single={o.single}
                  type={o.type}
                  sd={o.sd}
                  companyId={o.companyId}
                  propName={o.propName}
                  selectOptions={o.options}
                  pageParams={pageParams}
                  setPageParams={setPageParams}
                />
              ))}
            </div>
          </FiltersContainer>
        )}
      </FilterIconContainer>
    </Flex>
  )
}
