import { SingleFunction } from 'pages/Tasks/DragComponents/SingleFunction'
import { SingleInput } from 'pages/Tasks/DragComponents/SingleInput'
import { SingleOutput } from 'pages/Tasks/DragComponents/SingleOutput'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Button, Flex, TabFooter } from 'styling/GlobalStyles'
import { IProcessTemplateTab, IPTStateFunction } from 'types/processTemplates'
import { v4 } from 'uuid'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { ClearButton } from 'components/Button/ClearButton'
import { CancelBtn } from 'components/Button/CancelBtn'
import Xarrow, { Xwrapper } from 'react-xarrows'

export const ProcessTemplatesLogicTab = ({ state, setState, goToData, view }: IProcessTemplateTab) => {

    const handleAddFunc = (fn: IPTStateFunction) => {
        setState(prev => {
            const copy = structuredClone(prev)
            copy.processFunction = fn
            return copy
        })
    }

    const clearLogic = () => {
        setState(prev => {
            const copy = structuredClone(prev)
            copy.processFunction = null
            return copy
        })
    }

    return (
        <>
            <Flex between gap='2rem'>
                <DndProvider backend={HTML5Backend}>
                    <div className='container'>
                        <Xwrapper>
                            <div className='input-container'>
                                {state.processFunction?.inputs?.map((input) => {
                                    return (
                                        <SingleInput
                                            key={input.component}
                                            name={input.name}
                                            id={input.component.toString() + input.index.toString()}
                                            component={input.component}
                                            noDrag
                                        />
                                    )
                                })}
                            </div>
                            <div className='functions-container'>
                                {state.processFunction && <SingleFunction
                                    data={{ name: state.processFunction?.name, fid: state.processFunction.pfid }}
                                />}
                            </div>
                            <div className='output-container'>
                                {state.processFunction?.outputs?.map((output) => {
                                    return (
                                        <SingleOutput
                                            name={'Output ' + output.index}
                                            id={output.component}
                                            key={output.component}
                                            noDrag
                                        />
                                    )
                                })}
                            </div>
                            {state.processFunction?.inputs.map((item) => {
                                return (
                                    <Xarrow
                                        key={v4()}
                                        start={item.component.toString() + item.index.toString()}
                                        end={'func_' + state.processFunction?.pfid}
                                        color={'#FF9900'}
                                        strokeWidth={1}
                                        headShape={'circle'}
                                        headSize={10}
                                        startAnchor='bottom'
                                        endAnchor='top'
                                    />
                                )
                            })}
                            {state.processFunction?.outputs.map((item) => {
                                return (
                                    <Xarrow
                                        key={v4()}
                                        start={'func_' + state.processFunction?.pfid}
                                        end={item.component.toString()}
                                        color={'#DADADA'}
                                        strokeWidth={1}
                                        headShape={'circle'}
                                        headSize={10}
                                        endAnchor='top'
                                        startAnchor='bottom'
                                    />
                                )
                            })}
                        </Xwrapper>
                    </div>
                </DndProvider>
                <ItemsPick
                    link='process/function/configurations'
                    width='30%'
                    selectedList={state?.processFunction?.name ? [{ name: state?.processFunction?.name, pfid: state?.processFunction?.pfid }] : []}
                    oneColumn
                    noScroll
                    parserFunction={resp => resp}
                    handleAdd={handleAddFunc}
                    placeholder='Search Functions'
                    customSelectedProp='pfid'
                    view={view}
                />
            </Flex>
            {!view && <TabFooter>
                <Flex gap='1rem'>
                    <ClearButton text='Clear logic' onClick={clearLogic} />
                    <CancelBtn />
                    <Button orange onClick={goToData}>
                        Configure Data
                    </Button>
                </Flex>
            </TabFooter>}
        </>
    )
}