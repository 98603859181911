import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IDBMachinesSlice } from 'types/db-machines.interface'
import { baseUrl } from 'utils/axios'
import { handleErrors, formatParams } from 'utils/helpers'
import {
  IDBMachines, ISingleDBMachine, ISingleDBMachineTable,
} from './types'

const initialState: IDBMachinesSlice = {
  isLoading: false,
  machines: [],
  totalCount: 0,
  totalPages: 0,
  noMachines: false,
  singleDBMachine: null,
  dashboard: null,
}


export const getDBMachines = createAsyncThunk(
  'dashboardMachines/getAll',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<ISingleDBMachineTable>>= await baseUrl.get('dashboard/machines', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleDBMachine = createAsyncThunk(
  'dashboardMachines/getSingle',
  async (params:IGetSingleDBMachineParams, thunkAPI) => {
    const{id, utilizationInfoDateSpanType } = params
    try {
      const resp:AxiosResponse<ISingleDBMachine> = await baseUrl.get('machines/'+id+'/info',{params:{utilizationInfoDateSpanType}}) 
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)


const dashboardSlice = createSlice({
  name: 'dbMachinesSlice',
  initialState,
  reducers: {
    clearDBTMachinesList: (state) => {
      state.machines = []
      state.totalCount = 0
      state.totalPages = 0
    },
    clearSingleDBMachine: (state) => {
      state.singleDBMachine = null
    },
  },
  extraReducers: {
    [getDBMachines.pending.type]: (state) => {
      state.isLoading = true
    },
    [getDBMachines.fulfilled.type]: (
      state: IDBMachinesSlice,
      { payload }: { payload: IDBMachines },
    ) => {
      state.machines = payload.machines
      state.dashboard = payload.machineryDashboard
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noMachines = state.machines.length < 1
      state.isLoading = false
    },
    [getDBMachines.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleDBMachine.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleDBMachine.fulfilled.type]: (state: IDBMachinesSlice, { payload }) => {
      state.singleDBMachine = payload
      state.isLoading = false
    },
    [getSingleDBMachine.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearSingleDBMachine, clearDBTMachinesList } = dashboardSlice.actions

export default dashboardSlice.reducer

interface IGetSingleDBMachineParams {
  id:string
  utilizationInfoDateSpanType:number
}
