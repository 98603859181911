import styled from 'styled-components'
import { DBTaskTimelineItem } from '../DashboardTasks/DBTasks.styled'

interface IDBMachinesCard {
  total?: boolean
}
export const DBMachinesCard = styled.div<IDBMachinesCard>`
  height: 130px;
  width: 100%;
  ${(props) => props.total && 'min-width:20%;'}

  border-radius: 12.5px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;

  .svg {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      fill: ${(props) => props.theme.colorOrange};
      scale: 2;
    }
  }

  .details {
    height: 100%;
    width: 60%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${(props) =>
      props.total &&
      'border-left:0.5px solid ' + props.theme.colorLightGray + '; padding-left:1rem;'}

    h2 {
      font-size: 16px;
      color: ${(props) => props.theme.colorGray};
    }

    h1 {
      font-size: 32px;
      color: ${(props) => props.theme.colorOrange};
    }
  }
`

interface IDBMachineDetail {
  width: string
  bold?: boolean
}
export const DBMachineDetail = styled.p<IDBMachineDetail>`
  width: ${(props) => props.width};
  font-size: 13px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  color: ${(props) => props.theme.colorGray};
  border-bottom: 1px solid ${(props) => props.theme.colorLightGray};
  padding: 1rem 0;
`

interface IDBMachineStatusIcon {
  color: string
}
export const DBMachineStatusIcon = styled.div<IDBMachineStatusIcon>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  filter: drop-shadow(0px 0px 25.00447654724121px rgba(0, 0, 0, 0.1));

  svg {
    fill: ${(props) => props.color};
  }
`


export const DBMachineGraphBox = styled.div`
  border-radius: 12.5px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const DBMachineInfoSection = styled(DBTaskTimelineItem)`
padding: 1rem;
height: ${props => props.open ? 'calc-size(auto)' : '3.375rem'};
transition: height .2s;
user-select: none;
position: relative;

`

export const DBMachineRightSide = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 12.5px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
`

export const DBMachineMap = styled.div`
  margin: 1rem 0;
  width: 100%;
  height: 17rem;
  border-radius: 1rem;
`

export const DBMachineFuelLevelContainer = styled.div`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  height: max-content;
  cursor: pointer;
  animation: 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 4rem;
  padding: 0 1rem;
  margin:1rem 0;
`

export const DBMachineFuelLevelIndicator = styled.div<{level:number}>`
 height: 10px;
 border-radius: 50px;
 background-color: #e3e3e6;
 position: relative;
 width: 100%;


  &::before{
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: ${(props) => props.level}%;
    background-color: #FF9900;
    border-radius: 50px;
  }
`

export const TasksModalContainer = styled.div`
height: 80vh;
width: 40vw;
display: flex;
flex-direction: column;
gap: 1rem;

.list{
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

`