import React, { useEffect, useRef, useState } from 'react'
import { handleFocusNext } from 'utils/helpers'
import { InvalidText } from '../../styling/GlobalStyles'
import { InputWrapper, Label } from './Input.styled'

export const Input = (props) => {
  const p = { ...props }
  const inputRef = useRef(null)
  const wrapperRef = useRef(null)
  const { labelText, minHeight, nomarg, value, readOnly } = p
  delete p.labelText
  delete p.minHeight
  delete p.nomarg
  const [blured, setBlured] = useState(false)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.autocomplete = 'off'
    }
  }, [])

  const parsedValue = value || ''
  const shadow = parsedValue ? '0px 0px 30px rgba(0, 0, 0, 0.1)' : 'none'

  const handleBlur = () => {
    setBlured(true)
  }

  const renderInput = () => {
    if (p.smallInput) {
      return (
        <input
          {...p}
          onBlur={handleBlur}
          ref={inputRef}
          value={parsedValue}
          onKeyDown={handleFocusNext}
          style={{ pointerEvents: readOnly ? 'none' : 'all' }}
        />
      )
    }
    switch (p.type) {
      case 'textarea':
        return (
          <textarea
            onBlur={handleBlur}
            {...p}
            value={parsedValue}
            onKeyDown={handleFocusNext}
          ></textarea>
        )
      case 'number':
        return (
          <input
            {...p}
            value={parsedValue}
            onBlur={handleBlur}
            autoComplete='new-password'
            onWheel={(event) => event.currentTarget.blur()}
            ref={inputRef}
            onKeyDown={handleFocusNext}
          />
        )
      case 'tel':
        return (
          <input
            {...p}
            value={parsedValue}
            type='tel'
            ref={inputRef}
            onBlur={handleBlur}
            onKeyDown={handleFocusNext}
          />
        )
      default:
        return (
          <input
            {...p}
            onBlur={handleBlur}
            autoComplete='new-password'
            type={p.type ? p.type : 'text'}
            ref={inputRef}
            value={parsedValue}
            onKeyDown={handleFocusNext}
          />
        )
    }
  }


  const blockInteraction: any = p.view ? {
    userSelect: 'none',
    pointerEvents: 'none'
  } : {}

  return (
    <InputWrapper
      {...p}
      blured={blured ? 'true' : ''}
      minHeight={minHeight}
      nomarg={nomarg}
      ref={wrapperRef}
    >
      <Label htmlFor={p.id} labelText={labelText}>
        {labelText}
      </Label>
      <div
        style={{
          overflow: 'hidden',
          borderRadius: '8px',
          position: 'relative',
          boxShadow: shadow,
          ...blockInteraction
        }}
      >
        {renderInput()}
      </div>
      {!props.noinvalidtext && (
        <article>
          {p.invalid && blured && <InvalidText>{props.customInvalidMessage ?? 'Input is invalid or missing'}</InvalidText>}
        </article>
      )}
    </InputWrapper>
  )
}
