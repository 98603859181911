import React from 'react'
import { SingleFunctionInput } from './SingleFunctionInput'
import { SingleFunctionOutput } from './SingleFunctionOutput'
import { IWorkingFunctions } from 'types/tasks.interface'

export const SingleFunction = ({
  data,
  setAddedButNotConnected,
  setState,
  allFunctions
}: ISingleFunction) => {
  const { fid, name, inputs, outputs } = (data as IWorkingFunctions)
  return (
    <div id={'func_' + fid} className='single-function'>
      <div className='functionInputs'>
        {inputs?.map((i) => (
          <SingleFunctionInput
            input={{
              id: fid + '-' + i.component,
              name: i.name,
              order: i.order,
              component: i.component,
              fid,
            }}
            key={i.order}
            setState={setState}
            setAddedButNotConnected={setAddedButNotConnected}
            allFunctions={allFunctions}
          />
        ))}
      </div>

      <div className='functionText' id={'func_' + fid}>
        <p>{name}</p>
      </div>

      <div className='functionOutputs'>
        {outputs?.map((output) => (
          <SingleFunctionOutput
            output={{
              name: output.name,
              id: fid + '-' + output.component,
              order: output.order,
              component: output.component,
              fid,
            }}
            key={output.order}
          />
        ))}
      </div>
    </div>
  )
}

interface ISingleFunction {
  data: IWorkingFunctions | { name: string, fid: number }
  setAddedButNotConnected?: React.Dispatch<React.SetStateAction<IWorkingFunctions[]>>
  allFunctions?: IWorkingFunctions[]
  setState?: any
}
