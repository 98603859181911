import React from 'react'
import { TdWrapper, TdTooltip } from './Td.styled'
import { CSSProperties } from 'styled-components'

interface TdProps {
  text?: string | number
  w?: string
  children?: any
  noTooltip?: boolean
  svgColor?: string
  marginLeft?: string
  toolTipText?: string
  onClick?: () => any
  cutText?: boolean
  tooltipLeft?: boolean
  action?: boolean
}

export const Td = ({ text, w, children, noTooltip, svgColor, marginLeft, toolTipText, onClick, cutText, tooltipLeft, action }: TdProps) => {

  const style: CSSProperties = !action ? { position: 'relative', width: w, pointerEvents: 'none', marginLeft } : { position: 'absolute', width: w, pointerEvents: 'none', right: '0', top: '50%', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center', justifyContent: 'center' }

  return (
    <div onClick={onClick} style={style}>
      <TdWrapper cutText={cutText} noTooltip={(!text || noTooltip) && !toolTipText} svgColor={svgColor}>
        {children || text}
        {(text || toolTipText) && <TdTooltip left={tooltipLeft} className='tooltip' >{text || toolTipText}</TdTooltip>}
      </TdWrapper>
    </div>
  )
}
