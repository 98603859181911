/* eslint-disable*/
// @ts-nocheck TOOD

import React, { useEffect, useState } from 'react'
import {
  PageFooter,
  Animation,
  Flex,
  Title,
  Button,
  ImageUpload,
  AddButton,
  SelectImageContainer,
  SelectedItemsContainer,
} from 'styling/GlobalStyles'
import { CollapseItemDesignTask } from './CollapseItemDesignTask'
import { components } from './taskData'
import { ITaskComponent, ITasksTab, ITaskState, ITaskStateComponent } from 'types/tasks.interface'
import { v4 } from 'uuid'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { AddCircle } from 'assets/icons/AddCircle'
import { getBase64 } from 'utils/helpers'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { ToggleButton } from 'components/Input/ToggleButton'

export const TaskDesignTab = ({ state, setState, handleSubmit }: ITasksTab) => {
  const { id } = useParams()
  const [hasImage, setHasImage] = useState(false)

  console.log(state)

  useEffect(() => {
    id &&
      axios
        .get(process.env.REACT_APP_S3 + 'admin/task-templates/' + id)
        .then(() => setHasImage(true))
        .catch(() => setHasImage(false))
  }, [])

  const handleAdd = (item: ITaskStateComponent) => {
    if (!state.components.some((i: ITaskStateComponent) => i.component == item.component)) {
      setState((prev: ITaskState) => {
        const copy = structuredClone(prev)
        copy.components.push({ ...item, id: v4() })
        return copy
      })
    } else if (state.components.some((i: ITaskStateComponent) => i.component == item.component)) {
      setState((prev: ITaskState) => {
        const copy = { ...prev }
        copy.components = copy.components.filter(
          (it: ITaskStateComponent) => it.component !== item.component,
        )
        return copy
      })
    }
  }

  const handleImage = (e) => {
    getBase64(e.target.files[0]).then((res: string) =>
      setState((prev) => {
        const copy = { ...prev }
        copy.image = res
        setHasImage(true)
        return copy
      }),
    )
  }

  return (
    <Animation>
      <Flex top between minHeight='600px'>
        <Flex column width='25%' gap='1rem'>
          <ImageUpload width='100%'>
            {!hasImage ? (
              <>
                <AddButton
                  width='100%'
                  height='200px'
                  onClick={(e: any) => {
                    e.target.parentElement.children[1].click()
                  }}
                >
                  <AddCircle /> Add image
                </AddButton>
              </>
            ) : (
              <>
                <SelectImageContainer
                  style={{ cursor: 'pointer' }}
                  onClick={(e: any) => {
                    e.target.parentElement.parentElement.children[1].click()
                  }}
                >
                  <img
                    src={
                      state.image
                        ? state.image
                        : process.env.REACT_APP_S3 + 'admin/task-templates/' + id
                    }
                    alt=''
                  />
                </SelectImageContainer>
              </>
            )}
            <input type='file' name='imageupload' accept='image/*' onChange={handleImage} />
          </ImageUpload>
          <ToggleButton
            state={state.shouldUseLocationTracking}
            handleChange={() =>
              setState((prev: ITaskState) => ({
                ...prev,
                shouldUseLocationTracking: !prev.shouldUseLocationTracking,
              }))
            }
            label='Should Use Location Tracking'
            maxWidth
          />
        </Flex>
        <SelectedItemsContainer>
          <Title fontSize='0.9rem' pad='0 20px'>
            Selected Components
          </Title>
          {state.components?.map((item: ITaskComponent) => {
            return (
              <CollapseItemDesignTask
                state={state}
                setState={setState}
                key={item.component}
                data={item}
              />
            )
          })}
        </SelectedItemsContainer>
        <ItemsPick
          width='30%'
          fixedList={components.map((c) => ({ ...c, id: c.component }))}
          selectedList={state.components?.map((c: ITaskComponent) => ({
            ...c,
            id: c.component,
          }))}
          handleAdd={handleAdd}
        />
      </Flex>
      <PageFooter>
        <Flex gap='1rem'>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </PageFooter>
    </Animation>

  )
}