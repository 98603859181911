import {
    DashboardModalArrow,
    DashboardModalContainer,
    DashboardModalContent,
    DashboardModalWrapper,
} from './Dashboard.styled'
import { AlertIcon } from 'assets/icons/AlertIcon'
import { Flex, Table, Th, Tr } from 'styling/GlobalStyles'
import { Td } from 'components/Td/Td'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import { millisecondsToHMS, formatParams, setState, getTimeFromDate, convertToDate } from 'utils/helpers'
import { useCallback, useRef, useState } from 'react'
import { IDelayedTasksData } from 'features/dashboard/types'
import usePaginate from 'hooks/usePaginate'
import { DatePickerInput } from 'components/DatePicker/DatePicker'
import { useNavigate } from 'react-router-dom'
import { Title } from 'components/Dashboard/Title'

export const AlertsModal = ({ setModal, modal }) => {
    const [params, setParams] = useState({ page: 1, limit: 20, date: new Date(convertToDate(new Date())) })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const observer: any = useRef()
    const tableRows = { ths: [], trs: [] }
    let width = '60vw'
    let link = ''
    let parserFunction
    switch (modal.alertId) {
        case 'Problem Reports':
            link = 'problem-reports'
            parserFunction = (data) => {
                setTotal(data.totalCount)
                return data.reports
            }
            width = '70vw'
            break;
        case 'Restricted Area Breaches':
            tableRows.ths = [
                { l: 'AREA NAME', w: '30%' },
                { l: 'EMPLOYEE NAME', w: '30%' },
                { l: 'BREACH TIME', w: '30%' },
            ]
            tableRows.trs = [
                { l: 'areaName', w: '30%' },
                { l: 'employeeName', w: '30%' },
                { l: 'breachTime', w: '30%', c: '#FF4F46' },
            ]
            link = 'restrictedAreaBreaches'
            parserFunction = data => {
                setTotal(data.totalCount)
                return data.data
            }
            break
        case 'Delayed Tasks':
            tableRows.ths = [
                { l: 'TASK NAME', w: '30%' },
                { l: 'EMPLOYEE NAME', w: '30%' },
                { l: 'DELAYED TIME', w: '30%' },
            ]
            tableRows.trs = [
                { format: (d: IDelayedTasksData) => d.task?.taskTemplate ? d.task?.taskTemplate?.name : d.task?.process?.template?.name, w: '30%' },
                { w: '30%', format: (d: IDelayedTasksData) => d.firstName + ' ' + d.lastName },
                {
                    w: '30%',
                    c: '#FF4F46',
                    format: (d: IDelayedTasksData) => millisecondsToHMS(d.delayedTime),
                },
            ]
            link = 'delayed-tasks'
            parserFunction = data => {
                setTotal(data.totalCount)
                return data.data
            }
            break
        case 'Unusual Activity Detection':
            tableRows.ths = [
                { l: 'ACTIVITY NAME', w: '30%' },
                { l: 'LOCATION', w: '30%' },
                { l: 'TIME', w: '30%' },
            ]
            tableRows.trs = [
                { l: 'activityName', w: '30%' },
                { l: 'location', w: '30%' },
                { l: 'time', w: '30%', c: '#FF4F46' },
            ]
            link = 'unusualActivity'
            parserFunction = data => {
                setTotal(data.totalCount)
                return data.data
            }
            break
        case 'Resource Overuse Alert':
            tableRows.ths = [
                { l: 'MACHINE NAME', w: '30%' },
                { l: 'MACHINE MODEL', w: '30%' },
                { l: 'OVERUSED', w: '30%' },
            ]
            tableRows.trs = [
                { l: 'machineName', w: '30%' },
                { l: 'machineModel', w: '30%' },
                { l: 'overused', w: '30%', c: '#FF4F46' },
            ]
            parserFunction = data => {
                setTotal(data.totalCount)
                return data.data
            }
            link = 'resourceOveruse'
            break
        default:
            tableRows.ths = [
                { l: 'MACHINE NAME', w: '20%' },
                { l: 'MACHINE MODEL', w: '20%' },
                { l: 'EMPLOYEE', w: '20%' },
                { l: 'OVERUSED', w: '20%' },
            ]
            tableRows.trs = [
                { l: 'machineName', w: '20%' },
                { l: 'machineModel', w: '20%' },
                { l: 'EMPLOYEE', w: '20%' },
                { l: 'overused', w: '20%', c: '#FF4F46' },
            ]
            parserFunction = data => {
                setTotal(data.totalCount)
                return data.data
            }
            link = 'delayed-tasks'
    }


    const lastPostElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setParams((prev: any) => {
                        const copy = structuredClone(prev)
                        copy.page = copy.page + 1
                        return copy
                    });
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading]
    );


    usePaginate('dashboard/' + link, formatParams(params), null, parserFunction, setList, null, null, null, true, null, setLoading)

    const handleDate = (date) => {
        setList([])
        setParams(prev => ({ ...prev, date: new Date(convertToDate(date)) }))
    }

    return (
        <DashboardModalWrapper>
            <DashboardModalContainer style={{ width: screen.availWidth < 500 ? '100%' : width }} >
                <Flex width='100%' between center>
                    <DashboardModalArrow onClick={() => setState(setModal, 'modalOpen', !modal.modalOpen)}>
                        {ArrowLeft}
                    </DashboardModalArrow>
                    <DatePickerInput
                        maxDate={new Date()}
                        label='Date'
                        date={new Date(params.date)}
                        onChange={handleDate}
                    />
                </Flex>

                <Title
                    center
                    mainIcon={AlertIcon}
                    height={'fit-content'}
                    title={modal.alertId}
                    color='#FF4F46'
                    rightSide={<h2 style={{ color: '#FF4F46' }}>{total}</h2>}
                />
                <DashboardModalContent width='100%' >
                    <Table width='100%' height='100%' modal={true}>
                        {modal.alertId == 'Problem Reports' ?
                            <>
                                <Th minWidth={0} next >
                                    <Td w='20%' noTooltip text='Task name' />
                                    <Td w='20%' noTooltip text='Employee' />
                                    <Td w='35%' noTooltip text='Problem' />
                                    <Td w='10%' noTooltip text='Solution' />
                                </Th>
                                {list.map((d, i) => <Tr overflowHidden key={i} minWidth={0} >
                                    <Td w='15%' text={d.taskTemplateName || '-'} />
                                    <Td w='15%' text={d.reporterFirstName + ' ' + d.reporterLastName} />
                                    <Td cutText w='30%' toolTipText={d.reporterFirstName + ' ' + d.reporterLastName
                                        + ' reported ' + d.issueName + ' at ' + d.locationName
                                        + ' at ' + getTimeFromDate(d.reportedTime)} >
                                        <p>{d.reporterFirstName + ' ' + d.reporterLastName} reported <br />
                                            <span style={{ color: '#f00' }}> {d.issueName} </span>
                                            at + {getTimeFromDate(d.reportedTime)}
                                        </p>
                                    </Td>
                                    <Td tooltipLeft cutText w='25%' toolTipText={!d.solutionUsers.length ? '' : (d.solutionUsers[0] && ((d.solutionUsers[0]?.firstName || ' ') + ' ' + (d.solutionUsers[0]?.lastName || '')))
                                        + (d.solutionUsers.length > 1 ? ' and other(s) ' : ' ') + (d.solutionTaskTemplateName || '')
                                        + (d.solutionEstimatedStartDate && ('at ' + getTimeFromDate(d.solutionEstimatedStartDate)))}  >
                                        <p>
                                            {d.solutionUsers[0] && ((d.solutionUsers[0]?.firstName || ' ') + ' ' + (d.solutionUsers[0]?.lastName || ''))} {d.solutionUsers.length > 1 && 'and other(s)'} <br /> {d.solutionTaskTemplateName || ''} <br />
                                            {d.solutionEstimatedStartDate && ('at ' + getTimeFromDate(d.solutionEstimatedStartDate))}
                                        </p>
                                    </Td>
                                </Tr>)}
                            </> :
                            <> <Th minWidth={0}>
                                {tableRows.ths.map((th, i) => (
                                    <Td w={th.w} noTooltip text={th.l} key={i} />
                                ))}
                            </Th>
                                {list.map((d, i) => (
                                    <Tr
                                        key={i}
                                        minWidth={0}
                                        onClick={modal.alertId == 'Delayed Tasks' ? () => navigate('/dashboard/tasks/' + d.task.id) : null}
                                        style={modal.alertId == 'Delayed Tasks' ? { cursor: 'pointer' } : {}}
                                        ref={list.length === i + 1 ? lastPostElementRef : null}
                                    >
                                        {tableRows.trs.map((tr, n) => (
                                            <Td key={n} w={tr.w} text={tr.l ? d[tr.l] : tr.format(d)} />
                                        ))}
                                    </Tr>
                                ))}
                            </>}
                    </Table>
                </DashboardModalContent>
            </DashboardModalContainer>
        </DashboardModalWrapper>
    )
}
