/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react'
import Chevron from 'assets/icons/Chevron'
import {
  ClearFilterButton,
  SearchBox,
  SelectedOption,
  SelectedOptionsContainer,
  SingleFilterContainer,
  SingleFilterContent,
  SingleFilterTitle,
} from './Filter.styled'
import { Flex } from 'styling/GlobalStyles'
import { SearchIcon } from 'assets/icons/SearchIcon'
import { CheckBox } from 'components/CheckBox/CheckBox'
import usePaginate from 'hooks/usePaginate'
import { formatParams } from 'utils/helpers'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { CloseIcon } from 'assets/icons/CloseIcon'

interface ISingleOption {
  name: string
  id: number | string
  setFilterParams: any
  filterParams: any
  objectName: string
  single?: boolean
  sd?: boolean
}
const SingleOption = ({ name, id, setFilterParams, filterParams, objectName, single, sd }: ISingleOption) => {
  const handleState = () => {

    setFilterParams((prev) => {
      const copy = structuredClone(prev)
      if (single) {
        if (sd) {
          let splitted = (id as string).split('-')
          copy[objectName] = splitted[0]
          copy.sd = splitted[1]
        } else {
          copy[objectName] = id + '_' + name
        }
        return copy
      }


      if (!copy.hasOwnProperty(objectName)) {
        copy[objectName] = []
      }

      if (copy[objectName]?.includes(id + '_' + name)) {
        copy[objectName] = copy[objectName].filter((o) => o !== id + '_' + name)
      } else {
        copy[objectName].push(id + '_' + name)
      }
      return copy
    })
  }

  return (
    <Flex between center padding='0 1rem' mb='5' onClick={handleState}>
      <CheckBox state={single ? sd ? filterParams[objectName] == (id as string).split('-')[0] && filterParams.sd == (id as string).split('-')[1] :
        filterParams[objectName] == id + '_' + name : filterParams[objectName]?.includes(id + '_' + name)} title={name} />
    </Flex>
  )
}

const SearchFilter = ({ query, setParams, inputRef, setAdditionalTrigger }) => {
  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      setAdditionalTrigger((prev) => !prev)
    }
  }

  return (
    <Flex center column padding='10px'>
      <SearchBox>
        <input
          type='text'
          value={query || ''}
          onChange={(e) =>
            setParams((prev) => {
              const copy = structuredClone(prev)
              copy.q = e.target.value
              return copy
            })
          }
          ref={inputRef}
          placeholder='Search...'
          onKeyDown={handleOnKeyDown}
        />
        {SearchIcon}
      </SearchBox>
    </Flex>
  )
}

export enum FilterEnum {
  'SEARCH' = 1,
  'FIXED_LIST' = 2,
  'SELECT' = 3,
  'ASYNC_SELECT' = 4,
}

export enum LinkEnum {
  'OPERATION_TYPES' = 'operations/users/operation-types',
  'ROLES' = 'roles',
  'INDUSTRIES' = 'industries',
  'SHIFTS' = 'shifts',
  'CATEGORIES' = 'categories',
  'MACHINE_CATEGORIES' = 'machine-categories'
}

export interface IFilter {
  type: FilterEnum
  name: string
  link?: LinkEnum
  state?: any
  filterParams: any
  setFilterParams: any
  handleSave: any
  companyId: boolean
  filterOptions?: { name: string, id: number | string }[]
  propName?: string
  selectOptions?: ISelectValue[]
  single?: boolean
  sd?: boolean,
  pageParams?: any
  setPageParams?: any
}

export const SingleFilter = ({
  type,
  name,
  link,
  filterParams,
  setFilterParams,
  companyId,
  filterOptions,
  propName,
  selectOptions,
  single,
  sd,
  pageParams,
}: IFilter) => {
  const [open, setOpen] = useState(false)
  const [params, setParams] = useState({ page: 1, limit: 5, q: '' })
  const [options, setOptions] = useState<{ name: string, id: string | number }[]>(filterOptions ?? [])
  const [additionalTrigger, setAdditionalTrigger] = useState(false)
  const inputRef = useRef(null)

  usePaginate(link, formatParams(params), null, null, setOptions, additionalTrigger, companyId, true)

  const handleObjectName = (link: LinkEnum) => {
    let objectName = ''
    switch (link) {
      case LinkEnum.ROLES:
        objectName = 'roleIds'
        break
      case LinkEnum.INDUSTRIES:
        objectName = 'industryIds'
        break
      case LinkEnum.OPERATION_TYPES:
        objectName = 'operationTypeIds'
        break
    }
    return objectName
  }

  const parseReponse = (data: any) => {
    return data.data ? data.data.map(i => ({ label: i.name, value: i.id })) : data.map(({ category }: any) => ({ label: category.name, value: category.id }))
  }

  const renderContent = ({ open }) => {
    switch (type) {
      case FilterEnum.SEARCH:
        return (
          <SingleFilterContent open={open}>
            <SearchFilter
              setAdditionalTrigger={setAdditionalTrigger}
              inputRef={inputRef}
              setParams={setParams}
              query={params.q}
            />

            <SelectedOptionsContainer>
              {filterParams[propName ?? handleObjectName(link)]?.map((p: string) => {
                const id = p.split('_')
                const onClick = () => {
                  const objectName = propName ?? handleObjectName(link)
                  setFilterParams(prev => {
                    const copy = structuredClone(prev)
                    if (copy[objectName]?.includes(p)) {
                      copy[objectName] = copy[objectName].filter((o) => o !== p)
                    } else {
                      copy[objectName].push(p)
                    }
                    return copy
                  })

                }
                return <SelectedOption onClick={onClick} >{id[1]} <CloseIcon /> </SelectedOption>
              })}

            </SelectedOptionsContainer>
            {options.map((o) => (
              <SingleOption
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                name={o.name}
                single={single}
                id={o.id}
                objectName={propName ?? handleObjectName(link)}
              />
            ))}
          </SingleFilterContent>
        )
      case FilterEnum.FIXED_LIST:
        return (
          <SingleFilterContent open={open}>
            {options.map((o) => (
              <SingleOption
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                name={o.name}
                sd={sd}
                id={o.id}
                single={single}
                objectName={propName}
              />
            ))}
          </SingleFilterContent>
        )
      case FilterEnum.SELECT:
        let selectDv = ({
          label: filterParams && filterParams[propName + '-name'] || pageParams && pageParams[propName + '-name'] || '',
          value: filterParams && filterParams[propName]
        })
        return (
          <SingleFilterContent open={open}>
            <Flex center width='100%' height='40px' padding='3rem 1rem'>
              <HonestSelectInput defaultValue={selectDv.value} options={selectOptions} onChange={(value: ISelectValue) => setFilterParams(prev => {
                const copy = structuredClone(prev)
                copy[propName] = value.value
                copy[propName + '-name'] = value.label
                return copy
              })} />
            </Flex>
          </SingleFilterContent>
        )
      case FilterEnum.ASYNC_SELECT:
        let asyncSelectDv = filterParams && filterParams[propName + '-name'] || pageParams && pageParams[propName + '-name'] || ''
        return (
          <SingleFilterContent open={open}>
            <Flex center width='100%' height='40px' padding='3rem 1rem'>
              <HonestSelectAsyncInput name='filter' defaultValue={asyncSelectDv} parserFunction={parseReponse} link={link} onChange={(value: ISelectValue) => {
                setFilterParams(prev => {
                  const copy = structuredClone(prev)
                  copy[propName] = value.value
                  copy[propName + '-name'] = value.label
                  return copy
                })
              }} />
            </Flex>
          </SingleFilterContent>

        )
    }
  }

  return (
    <SingleFilterContainer open={open}>
      <SingleFilterTitle open={open} onClick={() => setOpen(() => true)}>
        <Chevron sidebar />
        <Flex between width='100%' center>
          <h5>{name}</h5>
          {(filterParams[handleObjectName(link)]?.length > 0 || (single && filterParams[propName])) && (
            <ClearFilterButton
              onClick={() => {
                const values = structuredClone(filterParams)
                if (single) {
                  values[propName] = null
                  values[propName + '-name'] = null
                } else {
                  values[handleObjectName(link)] = []
                }
                if (sd) {
                  values.sd = null
                }
                setFilterParams(values)
              }}
            >
              Clear
            </ClearFilterButton>
          )}
        </Flex>
      </SingleFilterTitle>
      {renderContent({ open })}
    </SingleFilterContainer>
  )
}
