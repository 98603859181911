import { Operations } from '../pages/Operations/Operations'
import { AddOperation } from '../pages/Operations/AddOperation'
import { OperationPreview } from '../pages/Operations/OperationPreview'
import { EditOperation } from '../pages/Operations/EditOperation'
import { ResourcesFields } from '../pages/ResourcesAdmin/Fields/ResourcesFields'
import { AddFieldsCategory } from '../pages/ResourcesAdmin/Categories/AddFieldsCategory'
import { ResourcesModels } from '../pages/ResourcesAdmin/Models/ResourcesModels'
import { AddModel } from '../pages/ResourcesAdmin/Models/AddModel'
import { Companies } from '../pages/Companies/Companies'
import { Tasks } from '../pages/Tasks/Tasks'
import { ResourcesCategories } from '../pages/ResourcesAdmin/Categories/ResourcesCategories'
import { SingleResourcesCategory } from '../pages/ResourcesAdmin/Categories/SingleResourcesCategory'
import { AddCompany } from '../pages/Companies/AddCompany'
import { EditModel } from '../pages/ResourcesAdmin/Models/EditModel'
import { SingleCompany } from '../pages/Companies/SingleCompany'
import { EditCompany } from '../pages/Companies/EditCompany'
import { SingleField } from 'pages/ResourcesAdmin/Fields/SingleField'
import { AddTask } from 'pages/Tasks/AddTask'
import { EditTask } from 'pages/Tasks/EditTask'
import { Issues } from 'pages/Issues/Issues'
import { AddIssue } from 'pages/Issues/AddIssue'
import { Industries } from 'pages/Industries/Industries'
import { AddIndustry } from 'pages/Industries/AddIndustry'
import { EditIndustry } from 'pages/Industries/EditIndustry'
import { EditIssue } from 'pages/Issues/EditIssue'
import { InvSettingsFields } from 'pages/Inventory/Settings/InvSettingsFields'
import { InvSettingsCategories } from 'pages/Inventory/Settings/InvSettingsCategories'
import { SingleInvField } from 'pages/Inventory/Settings/SingleInvField'
import { SingleInvSettingsCategory } from 'pages/Inventory/Settings/SingleInvSettingsCategory'
import { AddFieldsInvCategory } from 'pages/Inventory/Settings/AddFieldsInvCategory'
import { Triggers } from 'pages/Triggers/IssueTriggers/IssueTriggers'
import { AddTriggers } from 'pages/Triggers/IssueTriggers/AddIssueTrigger'
import { EditTriggers } from 'pages/Triggers/IssueTriggers/EditIssueTrigger'
import { TasksTriggers } from 'pages/Triggers/TasksTriggers/TasksTriggers'
import { AddTaskTrigger } from 'pages/Triggers/TasksTriggers/AddTaskTrigger'
import { EditTaskTriggers } from 'pages/Triggers/TasksTriggers/EditTaskTigger'
import { Workflows } from 'pages/Workflows/Workflows'
import { AddWorkflow } from 'pages/Workflows/AddWorkflow'
import { EditWorkflow } from 'pages/Workflows/EditWorkflow'
import { WorkerComponents } from 'pages/WorkerComponents/WorkerComponents'
import { SingleWorkerComponent } from 'pages/WorkerComponents/SingleWorkerComponent'
import { EditRoleTemplate } from 'pages/RoleTemplates/EditRoleTemplate'
import { AddRoleTemplate } from 'pages/RoleTemplates/AddRoleTemplate'
import { SingleRoleTemplate } from 'pages/RoleTemplates/SingleRoleTemplate'
import { RoleTemplates } from 'pages/RoleTemplates/RoleTemplates'
import { ProcessTemplates } from 'pages/ProcessTemplates/ProcessTemplates'
import { AddProcessTemplate } from 'pages/ProcessTemplates/AddProcessTemplate'
import { ViewProcessTemplate } from 'pages/ProcessTemplates/ViewProcessTemplate'
import { Cycles } from 'pages/Cycles/Cycles'
import { AddCycle } from 'pages/Cycles/AddCycle'
import { ViewCycle } from 'pages/Cycles/ViewCycle'

export const adminRoutes = [
  {
    path: '/operations',
    Component: Operations,
  },
  {
    path: 'operations/add',
    Component: AddOperation,
  },
  {
    path: 'operations/:id',
    Component: OperationPreview,
  },
  {
    path: 'operations/:id/edit',
    Component: EditOperation,
  },
    {
    path: '/operations',
    Component: Operations,
  },
  {
    path: 'resources-role-templates',
    Component: RoleTemplates,
  },
  {
    path: 'resources-role-templates/:id',
    Component: SingleRoleTemplate,
  },
  {
    path: 'resources-role-templates/add',
    Component: AddRoleTemplate,
  },
  {
    path: 'resources-role-templates/:id/edit',
    Component: EditRoleTemplate,
  },

  {
    path: 'resources-fields',
    Component: ResourcesFields,
  },
  {
    path: 'resources-fields/:id',
    Component: SingleField,
  },

  {
    path: 'resources-fields/add',
    Component: AddFieldsCategory,
  },
  {
    path: 'resources-models',
    Component: ResourcesModels,
  },
  {
    path: 'resources-models/add',
    Component: AddModel,
  },
  {
    path: 'resources-models/:id',
    Component: EditModel,
  },
  {
    path: 'companies',
    Component: Companies,
  },
  {
    path: 'companies/:id',
    Component: SingleCompany,
  },
  {
    path: 'companies/add',
    Component: AddCompany,
  },
  {
    path: 'companies/edit/:id',
    Component: EditCompany,
  },
  {
    path: 'industries',
    Component: Industries,
  },
  {
    path: 'industries/add',
    Component: AddIndustry,
  },
  {
    path: 'industries/:id/edit',
    Component: EditIndustry,
  },
  {
    path: 'issues',
    Component: Issues,
  },
  {
    path: 'issues/add',
    Component: AddIssue,
  },
  {
    path: 'issues/:id/edit',
    Component: EditIssue,
  },
  {
    path: 'inventory-fields',
    Component: InvSettingsFields,
  },
  {
    path: 'inventory-fields/:id',
    Component: SingleInvField,
  },
  {
    path: 'inventory-categories',
    Component: InvSettingsCategories,
  },
  {
    path: 'inventory-categories/:id',
    Component: SingleInvSettingsCategory,
  },
  {
    path: 'inventory-categories/:id/add',
    Component: AddFieldsInvCategory,
  },
  {
    path: 'tasks',
    Component: Tasks,
  },
  {
    path: 'cycles',
    Component: Cycles,
  },
  {
    path: 'cycles/add',
    Component: AddCycle,
  },
  {
    path: 'cycles/:id',
    Component: ViewCycle,
  },
  {
    path: 'workflows',
    Component: Workflows,
  },
  {
    path: 'workflows/add',
    Component: AddWorkflow,
  },
  {
    path: 'workflows/:id',
    Component: EditWorkflow,
  },
  {
    path: 'process-templates',
    Component: ProcessTemplates,
  },
  {
    path: 'process-templates/add',
    Component: AddProcessTemplate,
  },
  {
    path: 'process-templates/:id',
    Component: ViewProcessTemplate,
  },
  {
    path: 'workflows/add',
    Component: AddWorkflow,
  },
  {
    path: 'workflows/:id',
    Component: EditWorkflow,
  },
  {
    path: 'tasks/add',
    Component: AddTask,
  },
  {
    path: 'tasks/:id/edit',
    Component: EditTask,
  },
  {
    path: 'tasks/:id/edit/operation',
    Component: EditTask,
  },
  {
    path: 'tasks/:id/edit/logic',
    Component: EditTask,
  },
  {
    path: 'tasks/:id/edit/design',
    Component: EditTask,
  },
  {
    path: 'tasks/:id/edit/workUnitAmounts',
    Component: EditTask,
  },
  {
    path: 'tasks/:id/edit/components',
    Component: EditTask,
  },
  {
    path: 'resources-categories',
    Component: ResourcesCategories,
  },
  {
    path: 'resources-categories/:id',
    Component: SingleResourcesCategory,
  },
  {
    path: 'resources-categories/:id/add',
    Component: AddFieldsCategory,
  },
  {
    path: 'triggers-issue',
    Component: Triggers,
  },
  {
    path: 'triggers-issue/add',
    Component: AddTriggers,
  },
  {
    path: 'triggers-issue/edit/:id',
    Component: EditTriggers,
  },
  {
    path: 'triggers-task',
    Component: TasksTriggers,
  },
  {
    path: 'triggers-task/add',
    Component: AddTaskTrigger,
  },
  {
    path: 'triggers-task/edit/:id',
    Component: EditTaskTriggers,
  },
  {
    path: 'tasks-worker-components',
    Component: WorkerComponents,
  },
  {
    path: 'tasks-worker-components/:id',
    Component: SingleWorkerComponent,
  },
]
