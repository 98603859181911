import React, { useState } from 'react'
import {
  Animation,
  Flex,
  Title,
  Button,
  SelectedItemsContainer,
  TabFooter,
} from 'styling/GlobalStyles'
import { CollapseItemOperationTask } from './CollapseItemOperationTask'
import { Modal } from 'components/Modal/Modal'
import { TaskOperationModal } from 'components/Modal/TaskOperationModal'
import { IOperation } from 'types/operations.interface'
import { ITasksTab, ITaskState } from 'types/tasks.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'

export const TaskOperationTab = ({ state, setState, handleSubmit }: ITasksTab) => {
  const [modal, setModal] = useState({
    isOpen: false,
    operation: null,
  })

  const openModal = (operation: IOperation) => {
    setModal({
      isOpen: true,
      operation,
    })
  }

  const closeModal = () => {
    setModal({
      isOpen: false,
      operation: null,
    })
  }

  const handleAdd = (item: IOperation, typeId: number) => {
    const type = item.types.find((item) => item.id === typeId)
    setState((prev: ITaskState) => {
      const copy = { ...prev }
      copy.operationTypeId = typeId
      copy.name = type.name
      copy.operationTypeParameterValues = type.parameters.map((p) =>
      ({
        operationTypeParameterId: Number(p.id),
        parameterId: Number(p.parameterId),
        desiredValue: null,
        minimumValue: null
      })
      )
      copy.selectedOperation = { ...item, type }
      return copy
    })
    closeModal()
  }
  return (
    <>
      <Animation>
        <Flex between minHeight='600px' gap='2rem'>
          <SelectedItemsContainer>
            <Title fontSize='0.9rem' pad='0 20px'>
              Selected Operation
            </Title>
            {state.selectedOperation && (
              <CollapseItemOperationTask
                setState={setState}
                state={state}
                key={state.selectedOperation?.id}
                data={state.selectedOperation}
              />
            )}
          </SelectedItemsContainer>
          <ItemsPick
            selectedList={[state.selectedOperation]}
            handleAdd={openModal}
            link='operations/filled'
          />
        </Flex>

        <TabFooter>
          <Flex gap='1rem'>
            <CancelBtn />
            <Button orange onClick={handleSubmit}>
              Save
            </Button>
          </Flex>
        </TabFooter>
      </Animation>
      {modal.isOpen && (
        <Modal black>
          <TaskOperationModal
            handleAdd={handleAdd}
            closeModal={closeModal}
            operation={modal.operation}
          />
        </Modal>
      )}
    </>
  )
}
