/*eslint-disable*/
import { NavBar } from 'components/NavBar/NavBar'
import { getSingleDBTask } from 'features/dashboard/dbTasksSlice'
import { IMachineTask, IUserTaskTemplate } from 'features/dashboard/types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { Animation, Flex, H1WithBack, PageControls, PageWrapper, Separator } from 'styling/GlobalStyles'
import { formatDateForDisplay, getTime, getTimeFromDate } from 'utils/helpers'
import { taskStatusesData } from '../dashboardData'
import { DBSingleTaskContainer, DBSingleTaskSection, DBTaskDetailsCard, DBTaskGrid, DBTaskGridCard, DBTaskLongCard, DBTaskStatus, DBTaskTimelineItem, DBTimelineLogContainer, DBTimelineTitle, TimelineLineContainer, TimelineLog } from './DBTasks.styled'
import { LocationIcon } from 'assets/icons/LocationIcon'
import { TruckIcon } from 'assets/icons/TruckIcon'
import { EmployeesIconBlank } from 'assets/icons/EmployeesIconBlank'
import { ImagesSlider } from 'components/ImagesSlider/ImagesSlider'

interface ITimelineItem {
    data: IUserTaskTemplate | IMachineTask
    handleImages: any
    ifFirst?: boolean
    ref?: any
}
export const TimelineItem = ({ data, handleImages, ifFirst, ref }: ITimelineItem) => {
    const [open, setOpen] = useState(ifFirst)
    const StatusIcon = taskStatusesData.find(s => s.id == data.status).icon as any
    return <DBTaskTimelineItem ref={ref} onClick={() => setOpen(prev => !prev)} >
        <DBTimelineTitle status={data.status} >
            <Flex column gap='8px' alignStart >
                <h3>{'executionPlans' in data ? (data.user.firstName + ' ' + data.user.lastName) : data.name}</h3>
                {open && <>
                    <p>{data.user.role?.name}</p>
                    {!('executionPlans' in data) && <p>{(data.user.firstName + ' ' + data.user.lastName)}</p>}
                    {data.estimatedStartDate && <p>{`Estimation Time: ${formatDateForDisplay(data.estimatedStartDate) + ' ' + getTimeFromDate(data?.estimatedStartDate)} - ${formatDateForDisplay(data?.estimatedEndDate) + ' ' + getTimeFromDate(data?.estimatedEndDate)}`}</p>}
                    {'executionPlans' in data && <p>{`Machine: ${data.executionPlans[0]?.machine?.name ?? '-'}`}</p>}
                </>}
            </Flex>
            {StatusIcon}
        </DBTimelineTitle>
        <DBTimelineLogContainer open={open} onClick={e => e.stopPropagation()} >
            <TimelineLineContainer>
                {
                    data.executions.map((e, i) => <TimelineLog key={i} >
                        <p className='time' >{formatDateForDisplay(e.from, true)} <br /> {getTimeFromDate(e.from)}</p>
                        <div className='svg' >
                            <svg width='30' height='50' xmlns='http://www.w3.org/2000/svg' overflow='visible' >
                                {!(e.status == 3 || e.status == 7) && <line x1='10' y1='15' x2='10' y2='60' stroke='orange' strokeWidth='3' />}
                                <circle cx='10' cy='10' r='7' fill='orange' strokeWidth='3' />
                                <circle cx='10' cy='10' r='3' fill='white' />
                            </svg>
                        </div>
                        <Flex column >
                            {/* @ts-ignore */}
                            <p className='task-status' >{taskStatusesData.find(s => s.id == e.status).name} <span style={{ color: 'red' }} >{e.status == 4 && (e.userTaskExecutionIssue?.issueTrigger?.issue?.name || e.executionIssue?.issueTrigger?.issue?.name)}</span></p>
                            {((e.status == 3 && e.completedImageUrls.length > 0) || (e.status !== 3 && e.reportedImageUrls.length > 0)) &&
                                <p className='view-image' onClick={() => handleImages(e.status == 3 ? e.completedImageUrls : e.reportedImageUrls, e.userTaskExecutionIssue?.description)}>View Image</p>}
                        </Flex>
                    </TimelineLog>
                    )
                }
            </TimelineLineContainer>
        </DBTimelineLogContainer>
    </DBTaskTimelineItem>
}

export const DBSingleTask = () => {

    const dispatch = useDispatch<AppDispatch>()
    const { id } = useParams()
    const [imagesModal, setImagesModal] = useState({ open: false, images: [], details: '' })
    const { singleDBTask } = useSelector((state: RootState) => state.dbTasks)


    useEffect(() => {
        dispatch(getSingleDBTask(id))
    }, [id])

    const getMachines = () => {
        const machines = []
        if (singleDBTask) {
            for (const ut of singleDBTask?.userTasks) {
                for (const m of ut?.executionPlans) {
                    m.machine?.name && machines.push(m.machine?.name)
                }
            }
        }
        return machines
    }

    const Status = ({ st }: { st?: number }) => {
        const status = taskStatusesData.find(s => s.id == (st ?? singleDBTask?.status))
        return <DBTaskStatus status={singleDBTask?.status} >
            <div className='svg' >
                {status.icon}
            </div>
            {!st && <p>{status.name}</p>}
        </DBTaskStatus>
    }

    const handleImages = (images: string[], details: string) => {
        setImagesModal(prev => {
            const copy = structuredClone(prev)
            copy.images = images
            copy.details = details
            copy.open = true
            return copy
        })
    }

    return <>
        <NavBar />
        <Animation>
            <PageWrapper noFooter>
                <PageControls >
                    <H1WithBack arrow title={singleDBTask?.cycleInfo ? singleDBTask.cycleInfo.templateStep.name : singleDBTask?.taskTemplate ? singleDBTask?.taskTemplate?.name : singleDBTask?.processInfo?.name} />
                    {Number.isInteger(singleDBTask?.status) && <Status />}
                </PageControls>
                <DBSingleTaskContainer>
                    <DBSingleTaskSection style={{ width: '60%' }} >
                        <h3 style={{ fontSize: '16px' }} >General Info</h3>
                        <DBTaskLongCard>
                            <div className='wrapper'>
                                <h1>Task Details</h1>
                                <Flex gap='1rem' between >
                                    <DBTaskDetailsCard>
                                        {LocationIcon}
                                        <h1>Location: </h1>
                                        <p>{singleDBTask?.location.name}</p>
                                    </DBTaskDetailsCard>
                                    <DBTaskDetailsCard>
                                        {TruckIcon}
                                        <h1>Machines: </h1>
                                        <p>{getMachines().length}</p>
                                    </DBTaskDetailsCard>
                                    <DBTaskDetailsCard>
                                        {EmployeesIconBlank}
                                        <h1>Employees: </h1>
                                        <p>{singleDBTask?.userTasks.length}</p>
                                    </DBTaskDetailsCard>
                                </Flex>
                            </div>

                        </DBTaskLongCard>
                        <DBTaskGrid>
                            <DBTaskGridCard>
                                <h1>Estimated Time</h1>
                                <Separator />
                                <Flex height='calc(100% - 53px)' column between >
                                    <div>
                                        <p><b>Estimated start date: </b></p>
                                        <p>{singleDBTask?.estimatedCost ?
                                            (formatDateForDisplay(singleDBTask?.estimatedStartDate) + ' ' + getTimeFromDate(singleDBTask?.estimatedStartDate))
                                            : '-'} </p>
                                    </div>
                                    <div>
                                        <p style={{ marginTop: '2px' }}><b>Estimated end date: </b></p>
                                        <p>{singleDBTask?.estimatedEndDate ?
                                            (formatDateForDisplay(singleDBTask?.estimatedEndDate) + ' ' + getTimeFromDate(singleDBTask?.estimatedEndDate))
                                            : '-'}</p>
                                    </div>
                                </Flex>
                            </DBTaskGridCard>
                            <DBTaskGridCard>
                                <h1>Actual Time</h1>
                                <Separator />
                                <Flex height='calc(100% - 53px)' column between >
                                    <div>
                                        <p><b>Actual start date: </b></p>
                                        <p>{singleDBTask?.actualStartDate ?
                                            (formatDateForDisplay(singleDBTask?.actualStartDate) + ' ' + getTimeFromDate(singleDBTask?.actualStartDate))
                                            : '-'} </p>
                                    </div>
                                    <div>
                                        <p style={{ marginTop: '2px' }}><b>Actual end date: </b></p>
                                        <p>{singleDBTask?.actualEndDate ?
                                            (formatDateForDisplay(singleDBTask?.actualEndDate) + ' ' + getTimeFromDate(singleDBTask?.actualEndDate))
                                            : '-'}</p>
                                    </div>
                                </Flex>
                            </DBTaskGridCard>
                            <DBTaskGridCard>
                                <h1>Cost</h1>
                                <Separator />
                                <Flex column height='calc(100% - 53px)' between >
                                    <Flex width='100%' between >
                                        <p>Estimated: </p>
                                        <p>
                                            {singleDBTask?.estimatedCost ? ('$' + singleDBTask?.estimatedCost.toFixed(2)) : '-'}
                                        </p>
                                    </Flex>
                                    <Flex width='100%' between >
                                        <p>Actual: </p>
                                        <p>
                                            {singleDBTask?.actualCost ? ('$' + singleDBTask?.actualCost.toFixed(2)) : '-'}
                                        </p>
                                    </Flex>
                                    <Flex width='100%' between >
                                        <p><b>Difference:</b></p>
                                        {singleDBTask?.cycleInfo ? <p>-</p> : <p
                                            style={{
                                                color: singleDBTask?.actualCost < singleDBTask?.estimatedCost ? '#00B04C' : '#f00'
                                            }}
                                        >
                                            {singleDBTask?.costDifference ? '$' + (singleDBTask?.costDifference.toFixed(2)) : '-'}
                                        </p>}
                                    </Flex>
                                </Flex>
                            </DBTaskGridCard>
                            <DBTaskGridCard>
                                <h1>Duration</h1>
                                <Separator />
                                <Flex height='calc(100% - 53px)' column between >
                                    <Flex width='100%' between ><p>Estimated: </p>
                                        <p>
                                            {singleDBTask?.estimatedDuration ? getTime(singleDBTask?.estimatedDuration) : '-'}
                                        </p>
                                    </Flex>
                                    <Flex width='100%' between ><p>Actual: </p>
                                        <p>
                                            {singleDBTask?.totalDuration ? getTime(singleDBTask?.totalDuration) : '-'}
                                        </p>
                                    </Flex>
                                    <Flex width='100%' between ><p><b>Difference:</b> </p>
                                        {singleDBTask?.cycleInfo ? <p>-</p> : <p
                                            style={{
                                                color: singleDBTask?.estimatedDuration >= singleDBTask?.totalDuration ? '#00B04C' : '#f00'
                                            }}
                                        > {singleDBTask?.durationDifference ? getTime(singleDBTask?.durationDifference) : '-'
                                            }</p>}
                                    </Flex>
                                </Flex>
                            </DBTaskGridCard>
                        </DBTaskGrid>
                    </DBSingleTaskSection>
                    <DBSingleTaskSection style={{ width: '40%' }} >
                        <h3 style={{ fontSize: '16px' }}>Task Timeline</h3>
                        <Flex column mt='20' >
                            {singleDBTask?.userTasks.map((ut, i) => <TimelineItem ifFirst={i == 0} key={ut.id} handleImages={handleImages} data={ut} />)}
                        </Flex>
                    </DBSingleTaskSection>
                </DBSingleTaskContainer>
            </PageWrapper>
        </Animation>
        {imagesModal.open && <ImagesSlider details={imagesModal.details} images={imagesModal.images} setImagesModal={setImagesModal} />}
    </>
}