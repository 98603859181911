import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { IDBTasksSlice } from 'types/db-tasks.interface'
import { baseUrl } from 'utils/axios'
import { handleErrors, formatParams } from 'utils/helpers'
import { IDBCyclesInfo, IDBWorkflowsInfo, ISingleDBCycle, ISingleDBTask } from './types'

const initialState: IDBTasksSlice = {
  isLoading: false,
  tasks: [],
  cycles: [],
  cyclesInfo: null,
  workflows: [],
  workflowsInfo: null,
  noWorkflows: false,
  totalCount: 0,
  totalPages: 0,
  noTasks: false,
  noCycles: false,
  tasksStats: null,
  singleDBTask: null,
}

export const getDBTasks = createAsyncThunk(
  'dashboardTasks/getAll',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params, false, true)
      const resp = await baseUrl.get('dashboard/tasks', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const getDBCycles = createAsyncThunk(
  'dashboardTasks/getAllCycles',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params, false, true)
      const resp: AxiosResponse<ISingleDBCycle[]> = await baseUrl.get('dashboard/task-cycles', {
        params,
      })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getDBWorkflows = createAsyncThunk(
  'dashboardTasks/getAllWorkflows',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params, false, true)
      const resp: AxiosResponse<ISingleDBCycle[]> = await baseUrl.get('dashboard/workflows', {
        params,
      })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getDBWorkflowsInfo = createAsyncThunk(
  'dashboardTasks/getAllWorkflowsInfo',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params, false, true)
      const resp: AxiosResponse<IDBWorkflowsInfo> = await baseUrl.get('dashboard/workflows/info', {
        params,
      })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getDBCyclesInfo = createAsyncThunk(
  'dashboardTasks/getAllCyclesInfo',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params, false, true)
      const resp: AxiosResponse<IDBCyclesInfo> = await baseUrl.get('dashboard/task-cycles/info', {
        params,
      })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleDBTask = createAsyncThunk(
  'dashboardTakss/getSingle',
  async (id: string, thunkAPI) => {
    try {
      const resp: AxiosResponse<ISingleDBTask> = await baseUrl.get('dashboard/task-details/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const dbTasksSlice = createSlice({
  name: 'dbTasksSlice',
  initialState,
  reducers: {
    clearDBTasksList: (state) => {
      state.tasks = []
      state.totalCount = 0
      state.totalPages = 0
    },
    clearDBCycles: (state) => {
      state.cycles = []
      state.cyclesInfo = null
    },
    clearDBWorkflows: (state) => {
      state.workflows = []
      state.workflowsInfo = null
    },
    clearSingleDBTask: (state) => {
      state.singleDBTask = null
    },
  },
  extraReducers: {
    [getDBTasks.pending.type]: (state) => {
      state.isLoading = true
    },
    [getDBTasks.fulfilled.type]: (state: IDBTasksSlice, { payload }) => {
      state.tasks = payload.tasks
      state.tasksStats = payload.tasksStats
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noTasks = state.tasks.length < 1
      state.isLoading = false
    },
    [getDBTasks.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getDBCycles.pending.type]: (state) => {
      state.isLoading = true
    },
    [getDBCycles.fulfilled.type]: (state: IDBTasksSlice, { payload }) => {
      state.cycles = payload
      state.noCycles = state.cycles.length < 1
      state.isLoading = false
    },
    [getDBCycles.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getDBCyclesInfo.pending.type]: (state) => {
      state.isLoading = true
    },
    [getDBCyclesInfo.fulfilled.type]: (
      state: IDBTasksSlice,
      { payload }: { payload: IDBCyclesInfo },
    ) => {
      state.cyclesInfo = payload
      state.isLoading = false
    },
    [getDBCyclesInfo.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getDBWorkflows.pending.type]: (state) => {
      state.isLoading = true
    },
    [getDBWorkflows.fulfilled.type]: (state: IDBTasksSlice, { payload }) => {
      state.workflows = payload
      state.noWorkflows = state.workflows.length < 1
      state.isLoading = false
    },
    [getDBWorkflows.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getDBWorkflowsInfo.pending.type]: (state) => {
      state.isLoading = true
    },
    [getDBWorkflowsInfo.fulfilled.type]: (
      state: IDBTasksSlice,
      { payload }: { payload: IDBWorkflowsInfo },
    ) => {
      state.workflowsInfo = payload
      state.isLoading = false
    },
    [getDBWorkflowsInfo.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleDBTask.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleDBTask.fulfilled.type]: (
      state: IDBTasksSlice,
      { payload }: { payload: ISingleDBTask },
    ) => {
      state.singleDBTask = payload
      state.isLoading = false
    },
    [getSingleDBTask.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearDBTasksList, clearSingleDBTask, clearDBCycles, clearDBWorkflows } =
  dbTasksSlice.actions

export default dbTasksSlice.reducer
