import { ArrowLeftColorless } from 'assets/icons/ArrowLeftColorless'
import { BlankFileIcon } from 'assets/icons/BlankFileIcon'
import { CheckIcon } from 'assets/icons/CheckIcon'
import { PendingIcon } from 'assets/icons/PendingIcon'
import { StopIcon } from 'assets/icons/StopIcon'
import { DatePickerInput } from 'components/DatePicker/DatePicker'
import { Filter } from 'components/Filter/Filter'
import { FilterEnum } from 'components/Filter/SingleFilter'
import { NavBar } from 'components/NavBar/NavBar'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Pagination } from 'components/Pagination/Pagination'
import { Td } from 'components/Td/Td'
import { endOfWeek } from 'date-fns/esm'
import { clearDBTasksList, getDBTasks } from 'features/dashboard/dbTasksSlice'
import useQueryPagination from 'hooks/useQueryPagination'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'store'
import { EditContainer, Flex, H1WithBack, PageControls, PageFooter, PageWrapper, Table, Th, Tr } from 'styling/GlobalStyles'
import { convertToDate, getParams, getTime, handlePagination } from 'utils/helpers'
import { DBCardsContainer } from '../Dashboard.styled'
import { taskStatusesData } from '../dashboardData'
import { DBTaskCard, DBTCDetails, DBTCIcon } from './DBTasks.styled'

export const DBTasks = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const [params, setParams] = useState<any>(getParams({
        location, ap: {
            dateFrom: new Date(searchParams.get('dateFrom') ?? new Date()),
            dateTo: endOfWeek(new Date(searchParams.get('dateTo') ?? new Date()), { weekStartsOn: 1 }),
        },
        localeDateString: true
    }))
    const navigate = useNavigate()
    const { tasks, tasksStats, totalPages, noTasks } = useSelector((state: RootState) => state.dbTasks)

    useQueryPagination({ fetchFunctions: [getDBTasks], cleanUpFunctions: [clearDBTasksList], params })

    const setDateFrom = (value) => {
        setParams(prev => {
            const copy = structuredClone(prev)
            copy.dateFrom = convertToDate(value)
            return copy
        })
    }

    const setDateTo = (value) => {
        setParams(prev => {
            const copy = structuredClone(prev)
            copy.dateTo = convertToDate(value)
            return copy
        })
    }

    const filters = [
        {
            type: FilterEnum.FIXED_LIST,
            name: 'Difference',
            state: null,
            propName: 's',
            sd: true,
            single: true,
            filterOptions: [
                {
                    name: 'Cost - From Low To High',
                    id: 'costDifference-a'
                },
                {
                    name: 'Cost - From High To Low',
                    id: 'costDifference-d'
                },
                {
                    name: 'Duration - From Low To High',
                    id: 'durationDifference-a'
                },
                {
                    name: 'Duration - From High To Low',
                    id: 'durationDifference-d'
                },
            ]
        },
        {
            type: FilterEnum.SELECT,
            name: 'Status',
            options: taskStatusesData.map(s => ({ label: s.name, value: s.id })),
            propName: 'status'
        },

    ]
    return <>
        <NavBar />
        <PageWrapper>
            <PageControls>
                <H1WithBack title='Tasks' arrow link='/dashboard' />
                <Flex gap='1rem' center>
                    <DatePickerInput
                        maxDate={new Date(params.dateTo)}
                        label='Start date'
                        date={new Date(params.dateFrom || new Date())}
                        setDate={setDateFrom}
                    />
                    <DatePickerInput
                        minDate={new Date(params.dateFrom)}
                        label='End date'
                        date={new Date(params.dateTo || new Date())}
                        setDate={setDateTo}
                    />
                    <Filter options={filters} setPageParams={setParams} />
                </Flex>
            </PageControls>
            <DBCardsContainer>
                <DBTaskCard>
                    <DBTCIcon>
                        {BlankFileIcon}
                    </DBTCIcon>
                    <DBTCDetails>
                        <h3>Total Tasks</h3>
                        <h1>{tasksStats?.totalTasks || 0}</h1>
                        <Flex column>
                            <p>{'Total Costs: $' + (tasksStats?.totalCost?.toFixed(2) ?? 0)}</p>
                            <p>{'Total Duration: ' + getTime(tasksStats?.totalDuration)}</p>
                        </Flex>
                    </DBTCDetails>
                </DBTaskCard>
                <DBTaskCard>
                    <DBTCIcon>
                        {PendingIcon}
                    </DBTCIcon>
                    <DBTCDetails next >
                        <h3>Total In Progress</h3>
                        <h1>{tasksStats?.inProgressTasks || 0}</h1>
                    </DBTCDetails>
                </DBTaskCard>
                <DBTaskCard>
                    <DBTCIcon>
                        {StopIcon}
                    </DBTCIcon>
                    <DBTCDetails>
                        <h3>Total Stopped</h3>
                        <h1>{tasksStats?.stoppedTasks || 0}</h1>
                        <Flex column>
                            <p>{'Total Costs: $' + (tasksStats?.stoppedTasksCost ?? 0)}</p>
                            <p>{'Total Duration: ' + getTime(tasksStats?.stoppedTasksDuration)}</p>
                        </Flex>
                    </DBTCDetails>
                </DBTaskCard>
                <DBTaskCard>
                    <DBTCIcon>
                        {CheckIcon}
                    </DBTCIcon>
                    <DBTCDetails>
                        <h3>Total Completed</h3>
                        <h1>{tasksStats?.completedTasks || 0}</h1>
                        <Flex column>
                            <p>{'Total Costs: $' + (tasksStats?.completedTasksCost ?? 0)}</p>
                            <p>{'Total Duration: ' + getTime(tasksStats?.completedTasksDuration)}</p>
                        </Flex>
                    </DBTCDetails>
                </DBTaskCard>
            </DBCardsContainer>
            {noTasks ? <NoItems marginTop='40px' title='Tasks' /> : <Table style={{ marginTop: '40px' }} >
                <Th next >
                    <Td w='15%' noTooltip text='Task Name' />
                    <Td w='20%' noTooltip text='Location' />
                    <Td w='18%' noTooltip text='Cost' />
                    <Td w='15%' noTooltip text='Duration' />
                    <Td w='15%' noTooltip text='Status' />
                    <Td w='5%' noTooltip text='Alerts' />
                </Th>

                {tasks.map((item) => {
                    return (
                        <Tr key={item.id}>
                            <Td w='12%' text={item.cycleInfo ? item.cycleInfo?.templateStep?.name : item.taskTemplate ? item?.taskTemplate?.name : item?.processInfo?.name} />
                            <Td w='16%' text={item.locationName} />
                            <Td w='15%'>
                                <p  >
                                    {'$ ' + item.estimatedCost + ' / '} {(item.actualCost ? <span style={{ color: item.actualCost <= item.estimatedCost ? '#00B04C' : '#f00' }} > {('$' + item.actualCost)}</span> : '-')}
                                </p>
                            </Td>
                            <Td w='12%'>
                                {getTime(item.estimatedDuration) + ' / '}  {item.totalDuration ? <span style={{
                                    color:
                                        item.estimatedDuration >= item.totalDuration ? '#00B04C' : '#f00'
                                }
                                } >{getTime(item.totalDuration)}</span> : '-'}
                            </Td>
                            <Td w='10%'>
                                <p style={(item.status == 4 || item.status == 3 || item.status == 7) ? { color: item.status == 3 ? '#00B04C' : '#F00' } : {}} >
                                    {taskStatusesData.find(s => s.id == item.status).name}
                                </p>
                            </Td>
                            <Td w='10%' >
                                <p style={item.issue ? { color: '#F00' } : {}} >{item.issue || '-'}</p>
                            </Td>
                            <Td noTooltip>
                                <EditContainer onClick={() => navigate('/dashboard/tasks/' + item.id)} >
                                    <Flex style={{ transform: 'rotate(180deg)' }} className='arrow-table' >
                                        {ArrowLeftColorless}
                                    </Flex>
                                </EditContainer>
                            </Td>
                        </Tr>
                    )
                })}
            </Table>}
        </PageWrapper>
        <PageFooter>
            <Pagination
                setPage={(page) => handlePagination(page, setParams, 30)}
                pageCount={Math.ceil(totalPages)}
                forcePage={(params.page ? params.page : 1) - 1}
            />
        </PageFooter>
    </>
}